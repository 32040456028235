import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  redemptionItemCount: {},
  redemptionItemList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadRedemptionItem: false,
  redemptionItemListAll: [],
  redemptionItemDetail: {},
  isProcessLoadRedemptionItemDetail: true,
  isRedemptionItemCreateSuccess: false,
  isRedemptionItemCreateFail: false,
  isRedemptionItemUpdateSuccess: false,
  isRedemptionItemUpdateFail: false,
  isRedemptionItemPublishSuccess: false,
  isRedemptionItemPublishFail: false,
  isRedemptionItemRemoveSuccess: false,
  isRedemptionItemRemoveFail: false,  

  redemptionRecordList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadRedemptionRecord: true,
  DcointTransactionCount: {},
  DcointTransaction: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
};

export default function DCoinRewardReducer(state = initState, action) {
 
  switch (action.type) {
    
    case actionTypes.GET_REDEMPTION_ITEM_COUNT: {
      
      return {
        ...state,
        redemptionItemCount: {}
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_COUNT_SUCCESS: {
    
      return {
        ...state,
        redemptionItemCount:action.data
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_LOAD: {
      let redemptionItemList = cloneDeep(state.redemptionItemList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        redemptionItemList.offset = action.data.offset;
        redemptionItemList.limit = action.data.limit;
      }

      return {
        ...state,
        redemptionItemList,
        isProcessLoadRedemptionItem: true,
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_LOAD_SUCCESS: {
      let redemptionItemList = cloneDeep(state.redemptionItemList);
      redemptionItemList.data = action.data.data;
      redemptionItemList.meta = {
        page: 1 + state.redemptionItemList.offset / state.redemptionItemList.limit,
        pageSize: state.redemptionItemList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.redemptionItemList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        isProcessLoadRedemptionItem: false,
        redemptionItemList
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_LOAD_FAIL: {
      return {
        ...state,
        isProcessLoadRedemptionItem: false,
      };
    }
    
    case actionTypes.GET_REDEMPTION_ITEM_LOAD_ALL: {
      
      return {
        ...state,
        redemptionItemListAll: []
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_LOAD_ALL_SUCCESS: {
  
      return {
        ...state,
        redemptionItemListAll: action.data
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_LOAD_ALL_FAIL: {
      return {
        ...state,
      };
    }
    

    case actionTypes.GET_REDEMPTION_ITEM_DETAIL: {
      let redemptionItemDetail = cloneDeep(state.redemptionItemDetail);
      
      return {
        ...state,
        redemptionItemDetail,
        isProcessLoadRedemptionItemDetail: true,
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_DETAIL_SUCCESS: {
      return {
        ...state,
        redemptionItemDetail: action.data,
        isProcessLoadRedemptionItemDetail: false,
      };
    }

    case actionTypes.GET_REDEMPTION_ITEM_DETAIL_FAIL: {
      return {
        ...state,
        isProcessLoadRedemptionItemDetail: false,
      };
    }


    case actionTypes.CREATE_REDEMPTION_ITEM: {
      return {
        ...state,
        isRedemptionItemCreateSuccess: false,
        isRedemptionItemCreateFail: false,
      };
    }

    case actionTypes.CREATE_REDEMPTION_ITEM_SUCCESS: {
      
      return {
        ...state,
        isRedemptionItemCreateSuccess: true
      };
    }

    case actionTypes.CREATE_REDEMPTION_ITEM_FAIL: {
      return {
        ...state,
        isRedemptionItemCreateFail: true
      };
    }

    case actionTypes.UPDATE_REDEMPTION_ITEM: {
      return {
        ...state,
        isRedemptionItemUpdateSuccess: false,
        isRedemptionItemUpdateFail: false,
      };
    }

    case actionTypes.UPDATE_REDEMPTION_ITEM_SUCCESS: {
      
      return {
        ...state,
        isRedemptionItemUpdateSuccess: true
      };
    }

    case actionTypes.UPDATE_REDEMPTION_ITEM_FAIL: {
      return {
        ...state,
        isRedemptionItemUpdateFail: true
      };
    }

    case actionTypes.PUBLISH_REDEMPTION_ITEM: {
      return {
        ...state,
        isRedemptionItemPublishSuccess: false,
        isRedemptionItemPublishFail: false,
      };
    }

    case actionTypes.PUBLISH_REDEMPTION_ITEM_SUCCESS: {
      
      return {
        ...state,
        isRedemptionItemPublishSuccess: true
      };
    }

    case actionTypes.PUBLISH_REDEMPTION_ITEM_FAIL: {
      return {
        ...state,
        isRedemptionItemPublishFail: true
      };
    }

    case actionTypes.REMOVE_REDEMPTION_ITEM: {
      return {
        ...state,
        isRedemptionItemRemoveSuccess: false,
        isRedemptionItemRemoveFail: false,
      };
    }

    case actionTypes.REMOVE_REDEMPTION_ITEM_SUCCESS: {
      
      return {
        ...state,
        isRedemptionItemRemoveSuccess: true
      };
    }

    case actionTypes.REMOVE_REDEMPTION_ITEM_FAIL: {
      return {
        ...state,
        isRedemptionItemRemoveFail: true
      };
    }
    

    case actionTypes.GET_REDEMPTION_RECORD: {
      let redemptionRecordList = cloneDeep(state.redemptionRecordList);
      redemptionRecordList.exportSuccess = false 
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        redemptionRecordList.offset = action.data.offset;
        redemptionRecordList.limit = action.data.limit;
      }

      return {
        ...state,
        redemptionRecordList,
        isProcessLoadRedemptionRecord: true,
      };
    }

    case actionTypes.GET_REDEMPTION_RECORD_SUCCESS: {
      let redemptionRecordList = cloneDeep(state.redemptionRecordList);
      
      if(!action.data.data.export)
      {
        redemptionRecordList.exportSuccess = false          
        redemptionRecordList.data = action.data.data;
        redemptionRecordList.meta = {
          page: 1 + state.redemptionRecordList.offset / state.redemptionRecordList.limit,
          pageSize: state.redemptionRecordList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.redemptionRecordList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        redemptionRecordList.exportSuccess = true
      }
      
      return {
        ...state,
        isProcessLoadRedemptionRecord: false,
        redemptionRecordList
      };
    }

    case actionTypes.GET_REDEMPTION_RECORD_FAIL: {
      return {
        ...state,
        isProcessLoadRedemptionRecord: false,
      };
    }


    case actionTypes.DCOIN_TRANSACTION_COUNT: {
      
      return {
        ...state,
        DcointTransactionCount: {}
      };
    }

    case actionTypes.DCOIN_TRANSACTION_COUNT_SUCCESS: {
    
      return {
        ...state,
        DcointTransactionCount:action.data
      };
    }

    case actionTypes.DCOIN_TRANSACTION_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.DCOIN_TRANSACTION_LIST: {
      let DcointTransaction = cloneDeep(state.DcointTransaction);
      DcointTransaction.exportSuccess = false

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        DcointTransaction.offset = action.data.offset;
        DcointTransaction.limit = action.data.limit;
      }

      return {
        ...state,
        DcointTransaction
      };
    }


    case actionTypes.DCOIN_TRANSACTION_LIST_SUCCESS: {
      let DcointTransaction = cloneDeep(state.DcointTransaction);

      if(!action.data.data.export)
      {
        DcointTransaction.exportSuccess = false
        DcointTransaction.data = action.data.data;
        DcointTransaction.meta = {
          page: 1 + state.DcointTransaction.offset / state.DcointTransaction.limit,
          pageSize: state.DcointTransaction.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.DcointTransaction.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        DcointTransaction.exportSuccess = true
      }

      return {
        ...state,
        DcointTransaction
      };
    }

    case actionTypes.DCOIN_TRANSACTION_LIST_FAIL: {
      return {
        ...state
      };
    }



    default:
      return state;
  }
}
