import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";



export function* getRedemptionItemCount() {
  
  yield takeEvery(actionTypes.GET_REDEMPTION_ITEM_COUNT, function*(action) {

    if(!action.data) action.data = {}
    
    let track_no = Helper.generateTrackNo(`REDEMPTIONITEMCOUNT`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getRedemptionItemCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.getRedemptionItemCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRedemptionItemCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRedemptionItemCountFail());
    }
  });
}

export function* getRedemptionItemLoad() {
  
  yield takeEvery(actionTypes.GET_REDEMPTION_ITEM_LOAD, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.removed) ? "removed" : ""
    let search = (action.data.search) ? action.data.search : ""
    let offset = (action.data.offset) ? action.data.offset : ""
    
    let track_no = Helper.generateTrackNo(`REDEMPTIONITEMLIST${status}${search}${offset}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getRedemptionItemLoad(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.getRedemptionItemLoadSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRedemptionItemLoadFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRedemptionItemLoadFail());
    }
  });
}

export function* getRedemptionItemLoadAll() {
  
  yield takeEvery(actionTypes.GET_REDEMPTION_ITEM_LOAD_ALL, function*(action) {

    if(!action.data) action.data = {}
    
    let track_no = Helper.generateTrackNo(`REDEMPTIONITEMLISTALL`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getRedemptionItemLoad(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.getRedemptionItemLoadAllSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRedemptionItemLoadAllFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRedemptionItemLoadAllFail());
    }
  });
}


export function* getRedemptionItemDetail() {
  
  yield takeEvery(actionTypes.GET_REDEMPTION_ITEM_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo(`REDEMPTIONITEMDETAIL`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getRedemptionItemDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.getRedemptionItemDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRedemptionItemDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRedemptionItemDetailFail());
    }
  });
}

export function* createRedemptionItem() {

  yield takeEvery(actionTypes.CREATE_REDEMPTION_ITEM, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATEREDEMPTIONITEM");    
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.createRedemptionItem(action.data);
     
      if (response.data.success) {
        yield put(actions.createRedemptionItemSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.createRedemptionItemFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createRedemptionItemFail());
    }
  });
}


export function* updateRedemptionItem() {
  yield takeEvery(actionTypes.UPDATE_REDEMPTION_ITEM, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDATEREDEMPTIONITEM");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateRedemptionItem(action.id, action.data);
      if (response.data.success) {
        if(response.data.data)
        {
          yield put(actions.updateRedemptionItemSuccess(response.data.data));

        }else{
          yield put(actions.updateRedemptionItemSuccess());
          yield put(actions.toastSuccess(response.data.message));

        }
      } else {
        alert(response.data.message);
        yield put(actions.updateRedemptionItemFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateRedemptionItemFail());
    }
  });
}


export function* publishRedemptionItem() {
  yield takeEvery(actionTypes.PUBLISH_REDEMPTION_ITEM, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PUBLISHREDEMPTIONITEM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.publishRedemptionItem(action.id, action.data);
      if (response.data.success) {
        if(response.data.data)
        {
          yield put(actions.publishRedemptionItemSuccess(response.data.data));

        }else{
          yield put(actions.publishRedemptionItemSuccess());
          yield put(actions.toastSuccess(response.data.message));

        }
      } else {
        alert(response.data.message);
        yield put(actions.publishRedemptionItemFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.publishRedemptionItemFail());
    }
  });
}


export function* removeRedemptionItem() {
  yield takeEvery(actionTypes.REMOVE_REDEMPTION_ITEM, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEREDEMPTIONITEM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeRedemptionItem(action.id, action.data);
      if (response.data.success) {
        if(response.data.data)
        {
          yield put(actions.removeRedemptionItemSuccess(response.data.data));

        }else{
          yield put(actions.removeRedemptionItemSuccess());
          yield put(actions.toastSuccess(response.data.message));

        }
      } else {
        alert(response.data.message);
        yield put(actions.removeRedemptionItemFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeRedemptionItemFail());
    }
  });
}



export function* redemptionRecordList() {
  
  yield takeEvery(actionTypes.GET_REDEMPTION_RECORD, function*(action) {

    if(!action.data) action.data = {}
    
    let item = (action.data.item_id) ? action.data.item_id : ""
    let user = (action.data.user_id) ? action.data.user_id : ""
    let status = (action.data.status) ? action.data.status : ""
    let group = (action.data.group) ? action.data.group : ""
    let filter_date = (action.data.filter_date) ? action.data.filter_date : ""
    let search = (action.data.search) ? action.data.search : ""
    let exportFile = (action.data.exportFile) ? 1 : ""

    let track_no = Helper.generateTrackNo(`REDEMPTIONRECORD${item}${user}${status}${group}${filter_date}${search}${exportFile}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.redemptionRecordList(action.data);
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
        
        yield put(actions.redemptionRecordListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.redemptionRecordListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.redemptionRecordListFail());
    }
  });
}




export function* loadDcoinTransactionCount() {
  
  yield takeEvery(actionTypes.DCOIN_TRANSACTION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DCOINTRANSACTIONCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDcoinTransactionCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDcoinTransactionCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDcoinTransactionCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDcoinTransactionCountFail());
    }
  });
}

export function* dcoinTransactionList() {
  
  yield takeEvery(actionTypes.DCOIN_TRANSACTION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let user = (action.data.user_id) ? action.data.user_id : ""
    let group = (action.data.group) ? action.data.group : ""
    let filter_date = (action.data.filter_date) ? action.data.filter_date : ""
    let search = (action.data.search) ? action.data.search : ""
    let exportFile = (action.data.exportFile) ? 1 : ""
    
    let track_no = Helper.generateTrackNo(`DCOINTRANSACTION${user}${group}${filter_date}${search}${exportFile}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.dcoinTransactionList(action.data);
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
        
        yield put(actions.dcoinTransactionListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.dcoinTransactionListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.dcoinTransactionListFail());
    }
  });
}




export default function* generalSaga() {
  
  yield all([fork(getRedemptionItemCount)]);
  yield all([fork(getRedemptionItemLoad)]); 
  yield all([fork(getRedemptionItemLoadAll)]); 
  yield all([fork(getRedemptionItemDetail)]); 
  yield all([fork(createRedemptionItem)]); 
  yield all([fork(updateRedemptionItem)]); 
  yield all([fork(publishRedemptionItem)]); 
  yield all([fork(removeRedemptionItem)]); 
  yield all([fork(redemptionRecordList)]); 
  yield all([fork(loadDcoinTransactionCount)]); 
  yield all([fork(dcoinTransactionList)]); 
}
