import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin/dcoinReward";
};

export const transURL = () => {
  return APIHost() + "/admin/payment/dcoin";
};

export const getRedemptionItemCount = (data) => {
  const url = `${baseURL()}/redemptionItem/count`;  
  return axios.post(url, data);
};

export const getRedemptionItemLoad = (data) => {
  const url = `${baseURL()}/redemptionItem/load`;  
  return axios.post(url, data);
};

export const getRedemptionItemDetail = (id, data) => {
  const url = `${baseURL()}/redemptionItem/load/${id}`;    
  return axios.post(url, data);
};

export const createRedemptionItem = (data) => {
  const url = `${baseURL()}/redemptionItem/create`;  
  return axios.post(url, data);
};

export const updateRedemptionItem = (id, data) => {
  const url = `${baseURL()}/redemptionItem/update/${id}`;    
  return axios.post(url, data);
};

export const publishRedemptionItem = (id, data) => {
  const url = `${baseURL()}/redemptionItem/publish/${id}`;  
  return axios.post(url, data);
};

export const removeRedemptionItem = (id, data) => {
  const url = `${baseURL()}/redemptionItem/remove/${id}`;  
  return axios.post(url, data);
};

export const redemptionRecordList = (data) => {
  const url = `${baseURL()}/redemptionRecord/load`;  
  return axios.post(url, data);
};

export const loadDcoinTransactionCount = (data) => {
  var url = `${transURL()}/transaction/count`;
  return axios.post(url,data);
};

export const dcoinTransactionList = (data) => {
  const url = `${transURL()}/transaction/load`;  
  return axios.post(url, data);
};