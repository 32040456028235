import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  stateDistrictList: [],
  languagesList: [],
  verificationCodeList: [],
  promotionList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  distanceList: [],
  driver_point: [],
  landfill_point: [],
  landfill_detail: {},
  isLandfillPointLoaded: false,
  isLandfillDetailLoaded: false,
  isAddLandfillPointSuccess: false,
  isEditLandfillPointSuccess: false,
  isPublishLandfillPointSuccess: false,
  isRemoveLandfillPointSuccess: false,
  isEditRecyclingSalesPriceSuccess: false,
  orderType: null,
  isOrderTypeSetSuccess: false,
  isOrderTypeSetFail: false,
  isOrderTypeRemoveSuccess: false,
  isDistanceSetSuccess: false,
  isDistanceSetFail: false,
  isDistanceRemoveSuccess: false,  
  landfillCostType:null,
  timeslot: [],
  settings: [],
  setting_value: null,
  isSettingUpdateSuccess: false,
  isSetLandfillAdjustmentSuccess: false,
  isRemoveLandfillAdjustmentSuccess: false,
  landfill_adjustment: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  landfill_adjustment_detail: {},
  isLandfillAdjustmentLoaded: false,
  isLandfillAdjustmentDetailLoaded: false,
  
  weighing_point: [],
  isWeighingPointLoaded: false,
  isAddWeighingPointSuccess: false,
  isEditWeighingPointSuccess: false,
  isRemoveWeighingPointSuccess: false,

  isPromotionActionSuccess: false,
  feedback_subject_list: {},
  feedbackList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  feedbackDetail: {},
  feedbackCount: {},
  isFeedbackResolvedSuccess: false,
  isMerchantRegistrationRejectedSuccess: false,
  isMerchantRegistrationVerifiedSuccess: false,
  merchantRegistrationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  merchantRegistrationDetail: {},
  merchantRegistrationCount: {},
  carbonFootprint: [],
  isCarbonFootprintSetSuccess: false,
  isCarbonFootprintSetFail: false,
  overallImpact: [],
  referralCommission: [],
  isReferralCommissionSetSuccess: false,
  isReferralCommissiontSetFail: false,
  isReferralLevelRemoveSuccess: false,
  isReferralLevelRemoveSetFail: false,
  inviteFriendSettings: [],
  isInviteFriendSettingsSetSuccess: false,
  isInviteFriendSettingsSetFail: false,
  driverSubscriptionSettings: [],
  isDriverSubscriptionSettingsSetSuccess: false,
  isDriverSubscriptionSettingsSetFail: false,

  charitiesList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadCharities: false,
  charitiesListAll: [],
  charitiesCount: {},
  charitiesDetail: {},
  isProcessLoadCharitiesDetail: true,
  isCharitiesCreateSuccess: false,
  isCharitiesCreateFail: false,
  isCharitiesUpdateSuccess: false,
  isCharitiesUpdateFail: false,
  isCharitiesSetSequenceSuccess: false,
  isCharitiesSetSequenceFail: false,
  isCharitiesRemoveSuccess: false,
  isCharitiesRemoveFail: false,  
  isCharitiesRestoreSuccess: false,
  isCharitiesRestoreFail: false,

  donationRecordList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadDonationList: true,
  donationDetail: {},
  isProcessLoadDonationDetail: true,
};

export default function generalReducer(state = initState, action) {
 
  switch (action.type) {
   
    case actionTypes.LOAD_STATE_DISTRICT_LIST: {
      return {
        ...state,
        stateDistrictList: []
      };
    }

    case actionTypes.LOAD_STATE_DISTRICT_LIST_SUCCESS: {
      return {
        ...state,
        stateDistrictList: action.data

      };
    }
    
    
    case actionTypes.LOAD_LANGUAGUES: {
      return {
        ...state,
        languagesList: []
      };
    }

    case actionTypes.LOAD_LANGUAGUES_SUCCESS: {
      return {
        ...state,
        languagesList: action.data

      };
    }

    case actionTypes.LOAD_VERIFICATION_CODE: {
      
      return {
        ...state,
        verificationCodeList: []
      };
    }

    case actionTypes.LOAD_VERIFICATION_CODE_SUCCESS: {
      
      return {
        ...state,
        verificationCodeList: action.data.data
      };
    }

    case actionTypes.LOAD_VERIFICATION_CODE_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_ORDER_TYPE: {
      return {
        ...state,
        orderType: null
      };
    }

    case actionTypes.LOAD_ORDER_TYPE_SUCCESS: {
      
      return {
        ...state,
        orderType: action.data
      };
    }

    case actionTypes.LOAD_ORDER_TYPE_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.SET_ORDER_TYPE: {
      return {
        ...state,
        isOrderTypeSetSuccess: false,
        isOrderTypeSetFail: false,
      };
    }

    case actionTypes.SET_ORDER_TYPE_SUCCESS: {
      
      return {
        ...state,
        isOrderTypeSetSuccess: true
      };
    }

    case actionTypes.SET_ORDER_TYPE_FAIL: {
      return {
        ...state,
        isOrderTypeSetFail: true
      };
    }
    
    case actionTypes.REMOVE_ORDER_TYPE: {
      return {
        ...state,
        isOrderTypeRemoveSuccess: false
      };
    }

    case actionTypes.REMOVE_ORDER_TYPE_SUCCESS: {
      
      return {
        ...state,
        isOrderTypeRemoveSuccess: true
      };
    }

    case actionTypes.REMOVE_ORDER_TYPE_FAIL: {
      return {
        ...state,
        isOrderTypeRemoveSuccess: false
      };
    }

    
    case actionTypes.SET_DISTANCE: {
      return {
        ...state,
        isDistanceSetSuccess: false,
        isDistanceSetFail: false,
      };
    }

    case actionTypes.SET_DISTANCE_SUCCESS: {
      
      return {
        ...state,
        isDistanceSetSuccess: true
      };
    }

    case actionTypes.SET_DISTANCE_FAIL: {
      return {
        ...state,
        isDistanceSetFail: true
      };
    }
    
    case actionTypes.REMOVE_DISTANCE: {
      return {
        ...state,
        isDistanceRemoveSuccess: false
      };
    }

    case actionTypes.REMOVE_DISTANCE_SUCCESS: {
      
      return {
        ...state,
        isDistanceRemoveSuccess: true
      };
    }

    case actionTypes.REMOVE_DISTANCE_FAIL: {
      return {
        ...state,
        isDistanceRemoveSuccess: false
      };
    }
    
    case actionTypes.LOAD_LANDFILL_COST_TYPE: {
      return {
        ...state,
        landfillCostType: null
      };
    }

    case actionTypes.LOAD_LANDFILL_COST_TYPE_SUCCESS: {
      
      return {
        ...state,
        landfillCostType: action.data
      };
    }

    case actionTypes.LOAD_LANDFILL_COST_TYPE_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.LOAD_TIME_SLOT: {
      return {
        ...state,
        timeslot: []
      };
    }

    case actionTypes.LOAD_TIME_SLOT_SUCCESS: {
      
      return {
        ...state,
        timeslot: action.data
      };
    }

    case actionTypes.LOAD_TIME_SLOT_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_DISTANCE_LIST: {
      
      return {
        ...state,
        distanceList: []
      };
    }

    case actionTypes.LOAD_DISTANCE_LIST_SUCCESS: {
     
      return {
        ...state,
        distanceList: action.data
      };
    }

    case actionTypes.LOAD_DISTANCE_LIST_FAIL: {
      return {
        ...state
      };
    }

    /***LandFill Point **/   
    case actionTypes.LANDFILL_POINT_LOAD: {
      
      return {
        ...state,
        landfill_point: [],
        isLandfillPointLoaded: false
      };
    }

    case actionTypes.LANDFILL_POINT_LOAD_SUCCESS: {
     
      return {
        ...state,
        landfill_point: action.data,
        isLandfillPointLoaded: true
      };
    }

    case actionTypes.LANDFILL_POINT_LOAD_FAIL: {
      return {
        ...state,
        isLandfillPointLoaded: false
      };
    }

    case actionTypes.LANDFILL_POINT_DETAIL: {
      
      return {
        ...state,
        landfill_detail: {},
        isLandfillDetailLoaded: false
      };
    }

    case actionTypes.LANDFILL_POINT_DETAIL_SUCCESS: {
     
      return {
        ...state,
        landfill_detail: action.data,
        isLandfillDetailLoaded: true
      };
    }

    case actionTypes.LANDFILL_POINT_DETAIL_FAIL: {
      return {
        ...state,
        isLandfillDetailLoaded: false
      };
    }

    
    case actionTypes.LANDFILL_POINT_ADD: {
      return {
        ...state,
        isAddLandfillPointSuccess: false
      };
    }

    case actionTypes.LANDFILL_POINT_ADD_SUCCESS: {
      return {
        ...state,
        isAddLandfillPointSuccess: true
      };
    }

    case actionTypes.LANDFILL_POINT_ADD_FAIL: {
      return {
        ...state,
        isAddLandfillPointSuccess: false
      };
    }

    case actionTypes.LANDFILL_POINT_EDIT: {
      return {
        ...state,
        isEditLandfillPointSuccess: false
      };
    }

    case actionTypes.LANDFILL_POINT_EDIT_SUCCESS: {
      return {
        ...state,
        isEditLandfillPointSuccess: true
      };
    }

    case actionTypes.LANDFILL_POINT_EDIT_FAIL: {
      return {
        ...state,
        isEditLandfillPointSuccess: false
      };
    }

    case actionTypes.LANDFILL_POINT_PUBLISH: {
      return {
        ...state,
        isPublishLandfillPointSuccess: false
      };
    }

    case actionTypes.LANDFILL_POINT_PUBLISH_SUCCESS: {
      return {
        ...state,
        isPublishLandfillPointSuccess: true
      };
    }

    case actionTypes.LANDFILL_POINT_PUBLISH_FAIL: {
      return {
        ...state,
        isPublishLandfillPointSuccess: false
      };
    }

    
    case actionTypes.LANDFILL_POINT_REMOVE: {
      return {
        ...state,
        isRemoveLandfillPointSuccess: false
      };
    }

    case actionTypes.LANDFILL_POINT_REMOVE_SUCCESS: {
      return {
        ...state,
        isRemoveLandfillPointSuccess: true
      };
    }

    case actionTypes.LANDFILL_POINT_REMOVE_FAIL: {
      return {
        ...state,
        isRemoveLandfillPointSuccess: false
      };
    }

    case actionTypes.EDIT_RECYCLING_SALES_PRICE: {
      return {
        ...state,
        isEditRecyclingSalesPriceSuccess: false
      };
    }

    case actionTypes.EDIT_RECYCLING_SALES_PRICE_SUCCESS: {
      return {
        ...state,
        isEditRecyclingSalesPriceSuccess: true
      };
    }

    case actionTypes.EDIT_RECYCLING_SALES_PRICE_FAIL: {
      return {
        ...state,
        isEditRecyclingSalesPriceSuccess: false
      };
    }



    
    
    case actionTypes.LANDFILL_ADJUSTMENT_SET: {
      return {
        ...state,
        isSetLandfillAdjustmentSuccess: false
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_SET_SUCCESS: {
      return {
        ...state,
        isSetLandfillAdjustmentSuccess: true
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_SET_FAIL: {
      return {
        ...state,
        isSetLandfillAdjustmentSuccess: false
      };
    }
    

    case actionTypes.LANDFILL_ADJUSTMENT_REMOVE: {
      return {
        ...state,
        isRemoveLandfillAdjustmentSuccess: false
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_REMOVE_SUCCESS: {
      return {
        ...state,
        isRemoveLandfillAdjustmentSuccess: true
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_REMOVE_FAIL: {
      return {
        ...state,
        isRemoveLandfillAdjustmentSuccess: false
      };
    }


    case actionTypes.LANDFILL_ADJUSTMENT_LOAD: {

      let landfill_adjustment = cloneDeep(state.landfill_adjustment);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        landfill_adjustment.offset = action.data.offset;
        landfill_adjustment.limit = action.data.limit;
      }

      return {
        ...state,
        landfill_adjustment,
        isLandfillAdjustmentLoaded: false
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_LOAD_SUCCESS: {

      let landfill_adjustment = cloneDeep(state.landfill_adjustment);

      landfill_adjustment.data = action.data.data;
      landfill_adjustment.meta = {
        page: 1 + state.landfill_adjustment.offset / state.landfill_adjustment.limit,
        pageSize: state.landfill_adjustment.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.landfill_adjustment.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        landfill_adjustment,
        isLandfillAdjustmentLoaded: true
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_LOAD_FAIL: {
      return {
        ...state,
        isLandfillAdjustmentLoaded: false
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_DETAIL: {
      
      return {
        ...state,
        landfill_adjustment_detail: {},
        isLandfillAdjustmentDetailLoaded: false
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_DETAIL_SUCCESS: {
     
      return {
        ...state,
        landfill_adjustment_detail: action.data,
        isLandfillAdjustmentDetailLoaded: true
      };
    }

    case actionTypes.LANDFILL_ADJUSTMENT_DETAIL_FAIL: {
      return {
        ...state,
        isLandfillAdjustmentDetailLoaded: false
      };
    }

    /***LandFill Point END **/  
    


    /***Weighing Point **/   
    case actionTypes.WEIGHING_POINT_LOAD: {
          
      return {
        ...state,
        weighing_point: [],
        isWeighingPointLoaded: false
      };
    }

    case actionTypes.WEIGHING_POINT_LOAD_SUCCESS: {
    
      return {
        ...state,
        weighing_point: action.data,
        isWeighingPointLoaded: true
      };
    }

    case actionTypes.WEIGHING_POINT_LOAD_FAIL: {
      return {
        ...state,
        isWeighingPointLoaded: false
      };
    }


    case actionTypes.WEIGHING_POINT_ADD: {
      return {
        ...state,
        isAddWeighingPointSuccess: false
      };
    }

    case actionTypes.WEIGHING_POINT_ADD_SUCCESS: {
      return {
        ...state,
        isAddWeighingPointSuccess: true
      };
    }

    case actionTypes.WEIGHING_POINT_ADD_FAIL: {
      return {
        ...state,
        isAddWeighingPointSuccess: false
      };
    }

    case actionTypes.WEIGHING_POINT_EDIT: {
      return {
        ...state,
        isEditWeighingPointSuccess: false
      };
    }

    case actionTypes.WEIGHING_POINT_EDIT_SUCCESS: {
      return {
        ...state,
        isEditWeighingPointSuccess: true
      };
    }

    case actionTypes.WEIGHING_POINT_EDIT_FAIL: {
      return {
        ...state,
        isEditWeighingPointSuccess: false
      };
    }


    case actionTypes.WEIGHING_POINT_REMOVE: {
      return {
        ...state,
        isRemoveWeighingPointSuccess: false
      };
    }

    case actionTypes.WEIGHING_POINT_REMOVE_SUCCESS: {
      return {
        ...state,
        isRemoveWeighingPointSuccess: true
      };
    }

    case actionTypes.WEIGHING_POINT_REMOVE_FAIL: {
      return {
        ...state,
        isRemoveWeighingPointSuccess: false
      };
    }

    /***Weighing Point END **/  

    case actionTypes.SETTING_LOAD: {
      return {
        ...state,
        settings: []
      };
    }

    case actionTypes.SETTING_LOAD_SUCCESS: {
      return {
        ...state,
        settings: action.data
      };
    }

    case actionTypes.SETTING_LOAD_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.GET_SETTING_VALUE: {
      return {
        ...state,
        setting_value: null
      };
    }

    case actionTypes.GET_SETTING_VALUE_SUCCESS: {
      return {
        ...state,
        setting_value: action.data
      };
    }

    case actionTypes.GET_SETTING_VALUE_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.SETTING_UPDATE: {
      return {
        ...state,
        isSettingUpdateSuccess: false
      };
    }

    case actionTypes.SETTING_UPDATE_SUCCESS: {
      return {
        ...state,
        isSettingUpdateSuccess: true
      };
    }

    case actionTypes.SETTING_UPDATE_FAIL: {
      return {
        ...state,
        isSettingUpdateSuccess: false
      };
    }

    /** FEEDBACK **/
    case actionTypes.LOAD_FEEDBACK_SUBJECT_LIST: {
      return {
        ...state,
        feedback_subject_list: {}
      };
    }

    case actionTypes.LOAD_FEEDBACK_SUBJECT_LIST_SUCCESS: {
      return {
        ...state,
        feedback_subject_list: action.data
      };
    }

    case actionTypes.LOAD_FEEDBACK_SUBJECT_LIST_FAIL: {
      return {
        ...state
      };
    }



    case actionTypes.LOAD_FEEDBACK_LIST: {
      let feedbackList = cloneDeep(state.feedbackList);
      if (
        action.data && 
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        feedbackList.offset = action.data.offset;
        feedbackList.limit = action.data.limit;
      }

      return {
        ...state,
        feedbackList
      };
    }

    case actionTypes.LOAD_FEEDBACK_LIST_SUCCESS: {
      let feedbackList = cloneDeep(state.feedbackList);
      feedbackList.data = action.data.data;
      feedbackList.meta = {
        page: 1 + state.feedbackList.offset / state.feedbackList.limit,
        pageSize: state.feedbackList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.feedbackList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        feedbackList
      };
    }

    case actionTypes.LOAD_FEEDBACK_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_FEEDBACK_DETAIL: {
      return {
        ...state,
        feedbackDetail: {}
      };
    }

    case actionTypes.LOAD_FEEDBACK_DETAIL_SUCCESS:
    {
      return {
        ...state,
        feedbackDetail: action.data
      };
    }

    case actionTypes.LOAD_FEEDBACK_DETAIL_FAIL: {
      return {
        ...state,
      };
    }

    
    case actionTypes.GET_FEEDBACK_COUNT: {
      return {
        ...state,
        feedbackCount: {}
      };
    }

    case actionTypes.GET_FEEDBACK_COUNT_SUCCESS:
    {
      return {
        ...state,
        feedbackCount: action.data
      };
    }

    case actionTypes.GET_FEEDBACK_COUNT_FAIL: {
      return {
        ...state,
      };
    }

    case actionTypes.RESOLVE_FEEDBACK: 
    case actionTypes.RESOLVE_FEEDBACK_FAIL: {
      return {
        ...state,
        isFeedbackResolvedSuccess: false
      };
    }

    case actionTypes.RESOLVE_FEEDBACK_SUCCESS:
    {
      return {
        ...state,
        isFeedbackResolvedSuccess: true
      };
    }
    /** End FEEDBACK **/
    

    /** MERCHANT REGISTRATION **/
    case actionTypes.LOAD_MERCHANT_REGISTRATION_LIST: {
      let merchantRegistrationList = cloneDeep(state.merchantRegistrationList);
      if (
        action.data && 
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        merchantRegistrationList.offset = action.data.offset;
        merchantRegistrationList.limit = action.data.limit;
      }

      return {
        ...state,
        merchantRegistrationList
      };
    }

    case actionTypes.LOAD_MERCHANT_REGISTRATION_LIST_SUCCESS: {
      let merchantRegistrationList = cloneDeep(state.merchantRegistrationList);
      merchantRegistrationList.data = action.data.data;
      merchantRegistrationList.meta = {
        page: 1 + state.merchantRegistrationList.offset / state.merchantRegistrationList.limit,
        pageSize: state.merchantRegistrationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.merchantRegistrationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        merchantRegistrationList
      };
    }

    case actionTypes.LOAD_MERCHANT_REGISTRATION_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_MERCHANT_REGISTRATION_DETAIL: {
      return {
        ...state,
        merchantRegistrationDetail: {}
      };
    }

    case actionTypes.LOAD_MERCHANT_REGISTRATION_DETAIL_SUCCESS:
    {
      return {
        ...state,
        merchantRegistrationDetail: action.data
      };
    }

    case actionTypes.LOAD_MERCHANT_REGISTRATION_DETAIL_FAIL: {
      return {
        ...state,
      };
    }

    
    case actionTypes.GET_MERCHANT_REGISTRATION_COUNT: {
      return {
        ...state,
        merchantRegistrationCount: {}
      };
    }

    case actionTypes.GET_MERCHANT_REGISTRATION_COUNT_SUCCESS:
    {
      return {
        ...state,
        merchantRegistrationCount: action.data
      };
    }

    case actionTypes.GET_MERCHANT_REGISTRATION_COUNT_FAIL: {
      return {
        ...state,
      };
    }


    case actionTypes.VERIFY_MERCHANT_REGISTRATION: 
    case actionTypes.VERIFY_MERCHANT_REGISTRATION_FAIL: {
      return {
        ...state,
        isMerchantRegistrationVerifiedSuccess: false
      };
    }

    case actionTypes.VERIFY_MERCHANT_REGISTRATION_SUCCESS:
    {
      return {
        ...state,
        isMerchantRegistrationVerifiedSuccess: true
      };
    }

    case actionTypes.REJECT_MERCHANT_REGISTRATION: 
    case actionTypes.REJECT_MERCHANT_REGISTRATION_FAIL: {
      return {
        ...state,
        isMerchantRegistrationRejectedSuccess: false
      };
    }

    case actionTypes.REJECT_MERCHANT_REGISTRATION_SUCCESS:
    {
      return {
        ...state,
        isMerchantRegistrationRejectedSuccess: true
      };
    }
    /** End MERCHANT REGISTRATION **/

    /** Promotion **/
    case actionTypes.LOAD_PROMOTION_LIST: {
      let promotionList = cloneDeep(state.promotionList);
      if (
        action.data && 
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        promotionList.offset = action.data.offset;
        promotionList.limit = action.data.limit;
      }

      return {
        ...state,
        promotionList
      };
    }

    case actionTypes.LOAD_PROMOTION_LIST_SUCCESS: {
      let promotionList = cloneDeep(state.promotionList);
      promotionList.data = action.data.data;
      promotionList.meta = {
        page: 1 + state.promotionList.offset / state.promotionList.limit,
        pageSize: state.promotionList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.promotionList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        promotionList
      };
    }

    case actionTypes.LOAD_PROMOTION_LIST_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.ADD_PROMOTION: 
    case actionTypes.ADD_PROMOTION_FAIL:
    case actionTypes.EDIT_PROMOTION:
    case actionTypes.EDIT_PROMOTION_FAIL:
    case actionTypes.DELETE_PROMOTION:
    case actionTypes.DELETE_PROMOTION_FAIL:  {
      return {
        ...state,
        isPromotionActionSuccess: false
      };
    }

    case actionTypes.ADD_PROMOTION_SUCCESS:
    case actionTypes.EDIT_PROMOTION_SUCCESS:
    case actionTypes.DELETE_PROMOTION_SUCCESS: 
    {
      return {
        ...state,
        isPromotionActionSuccess: true
      };
    }
    /** End Promotion **/
    

    
    case actionTypes.LOAD_CARBON_FOOTPRINT: {
      return {
        ...state,
        carbonFootprint: []
      };
    }

    case actionTypes.LOAD_CARBON_FOOTPRINT_SUCCESS: {
      
      return {
        ...state,
        carbonFootprint: action.data
      };
    }

    case actionTypes.LOAD_CARBON_FOOTPRINT_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.SET_CARBON_FOOTPRINT: {
      return {
        ...state,
        isCarbonFootprintSetSuccess: false,
        isCarbonFootprintSetFail: false,
      };
    }

    case actionTypes.SET_CARBON_FOOTPRINT_SUCCESS: {
      
      return {
        ...state,
        isCarbonFootprintSetSuccess: true
      };
    }

    case actionTypes.SET_CARBON_FOOTPRINT_FAIL: {
      return {
        ...state,
        isCarbonFootprintSetFail: true
      };
    }
    
    
    case actionTypes.LOAD_OVERALL_IMPACT: {
      return {
        ...state,
        overallImpact: []
      };
    }

    case actionTypes.LOAD_OVERALL_IMPACT_SUCCESS: {
      
      return {
        ...state,
        overallImpact: action.data
      };
    }

    case actionTypes.LOAD_OVERALL_IMPACT_FAIL: {
      return {
        ...state
      };
    }
    

    
    case actionTypes.LOAD_REFERRAL_COMMISSION: {
      return {
        ...state,
        referralCommission: {}
      };
    }

    case actionTypes.LOAD_REFERRAL_COMMISSION_SUCCESS: {
      
      return {
        ...state,
        referralCommission: action.data
      };
    }

    case actionTypes.LOAD_REFERRAL_COMMISSION_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.SET_REFERRAL_COMMISSION: {
      return {
        ...state,
        isReferralCommissionSetSuccess: false,
        isReferralCommissiontSetFail: false,
      };
    }

    case actionTypes.SET_REFERRAL_COMMISSION_SUCCESS: {
      
      return {
        ...state,
        isReferralCommissionSetSuccess: true
      };
    }

    case actionTypes.SET_REFERRAL_COMMISSION_FAIL: {
      return {
        ...state,
        isReferralCommissiontSetFail: true
      };
    }

    
    case actionTypes.REMOVE_REFERRAL_LEVEL: {
      return {
        ...state,
        isReferralLevelRemoveSuccess: false,
        isReferralLevelRemoveSetFail: false,
      };
    }

    case actionTypes.REMOVE_REFERRAL_LEVEL_SUCCESS: {
      
      return {
        ...state,
        isReferralLevelRemoveSuccess: true
      };
    }

    case actionTypes.REMOVE_REFERRAL_LEVEL_FAIL: {
      return {
        ...state,
        isReferralLevelRemoveSetFail: true
      };
    }

    

    
    case actionTypes.LOAD_INVITE_FRIEND_SETTING: {
      return {
        ...state,
        inviteFriendSettings: []
      };
    }

    case actionTypes.LOAD_INVITE_FRIEND_SETTING_SUCCESS: {
      
      return {
        ...state,
        inviteFriendSettings: action.data
      };
    }

    case actionTypes.LOAD_INVITE_FRIEND_SETTING_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.SET_INVITE_FRIEND_SETTING: {
      return {
        ...state,
        isInviteFriendSettingsSetSuccess: false,
        isInviteFriendSettingsSetFail: false,
      };
    }

    case actionTypes.SET_INVITE_FRIEND_SETTING_SUCCESS: {
      
      return {
        ...state,
        isInviteFriendSettingsSetSuccess: true
      };
    }

    case actionTypes.SET_INVITE_FRIEND_SETTING_FAIL: {
      return {
        ...state,
        isInviteFriendSettingsSetFail: true
      };
    }
    

    
    case actionTypes.LOAD_DRIVER_SUBSCRIPTION_SETTING: {
      return {
        ...state,
        driverSubscriptionSettings: []
      };
    }

    case actionTypes.LOAD_DRIVER_SUBSCRIPTION_SETTING_SUCCESS: {
      
      return {
        ...state,
        driverSubscriptionSettings: action.data
      };
    }

    case actionTypes.LOAD_DRIVER_SUBSCRIPTION_SETTING_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.SET_DRIVER_SUBSCRIPTION_SETTING: {
      return {
        ...state,
        isDriverSubscriptionSettingsSetSuccess: false,
        isDriverSubscriptionSettingsSetFail: false,
      };
    }

    case actionTypes.SET_DRIVER_SUBSCRIPTION_SETTING_SUCCESS: {
      
      return {
        ...state,
        isDriverSubscriptionSettingsSetSuccess: true
      };
    }

    case actionTypes.SET_DRIVER_SUBSCRIPTION_SETTING_FAIL: {
      return {
        ...state,
        isDriverSubscriptionSettingsSetFail: true
      };
    }
    

    
    /* ===== Donation ===== */

    case actionTypes.GET_CHARITIES_LOAD: {
      let charitiesList = cloneDeep(state.charitiesList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        charitiesList.offset = action.data.offset;
        charitiesList.limit = action.data.limit;
      }

      return {
        ...state,
        charitiesList,
        isProcessLoadCharities: true,
      };
    }

    case actionTypes.GET_CHARITIES_LOAD_SUCCESS: {
      let charitiesList = cloneDeep(state.charitiesList);
      charitiesList.data = action.data.data;
      charitiesList.meta = {
        page: 1 + state.charitiesList.offset / state.charitiesList.limit,
        pageSize: state.charitiesList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.charitiesList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        isProcessLoadCharities: false,
        charitiesList
      };
    }

    case actionTypes.GET_CHARITIES_LOAD_FAIL: {
      return {
        ...state,
        isProcessLoadCharities: false,
      };
    }
    
    case actionTypes.GET_CHARITIES_LOAD_ALL: {
      
      return {
        ...state,
        charitiesListAll: []
      };
    }

    case actionTypes.GET_CHARITIES_LOAD_ALL_SUCCESS: {
  
      return {
        ...state,
        charitiesListAll: action.data
      };
    }

    case actionTypes.GET_CHARITIES_LOAD_ALL_FAIL: {
      return {
        ...state,
      };
    }
    
    
    case actionTypes.GET_CHARITIES_COUNT: {
      
      return {
        ...state,
        charitiesCount: {}
      };
    }

    case actionTypes.GET_CHARITIES_COUNT_SUCCESS: {
    
      return {
        ...state,
        charitiesCount:action.data
      };
    }

    case actionTypes.GET_CHARITIES_COUNT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.GET_CHARITIES_DETAIL: {
      let charitiesDetail = cloneDeep(state.charitiesDetail);
      
      return {
        ...state,
        charitiesDetail,
        isProcessLoadCharitiesDetail: true,
      };
    }

    case actionTypes.GET_CHARITIES_DETAIL_SUCCESS: {
      return {
        ...state,
        charitiesDetail: action.data,
        isProcessLoadCharitiesDetail: false,
      };
    }

    case actionTypes.GET_CHARITIES_DETAIL_FAIL: {
      return {
        ...state,
        isProcessLoadCharitiesDetail: false,
      };
    }


    case actionTypes.CREATE_CHARITIES: {
      return {
        ...state,
        isCharitiesCreateSuccess: false,
        isCharitiesCreateFail: false,
      };
    }

    case actionTypes.CREATE_CHARITIES_SUCCESS: {
      
      return {
        ...state,
        isCharitiesCreateSuccess: true
      };
    }

    case actionTypes.CREATE_CHARITIES_FAIL: {
      return {
        ...state,
        isCharitiesCreateFail: true
      };
    }

    case actionTypes.UPDATE_CHARITIES: {
      return {
        ...state,
        isCharitiesUpdateSuccess: false,
        isCharitiesUpdateFail: false,
      };
    }

    case actionTypes.UPDATE_CHARITIES_SUCCESS: {
      
      return {
        ...state,
        isCharitiesUpdateSuccess: true
      };
    }

    case actionTypes.UPDATE_CHARITIES_FAIL: {
      return {
        ...state,
        isCharitiesUpdateFail: true
      };
    }

    case actionTypes.SET_CHARITIES_SEQUENCE: {
      return {
        ...state,
        isCharitiesSetSequenceSuccess: false,
        isCharitiesSetSequenceFail: false,
      };
    }

    case actionTypes.SET_CHARITIES_SEQUENCE_SUCCESS: {
      
      return {
        ...state,
        isCharitiesSetSequenceSuccess: true
      };
    }

    case actionTypes.SET_CHARITIES_SEQUENCE_FAIL: {
      return {
        ...state,
        isCharitiesSetSequenceFail: true
      };
    }

    case actionTypes.REMOVE_CHARITIES: {
      return {
        ...state,
        isCharitiesRemoveSuccess: false,
        isCharitiesRemoveFail: false,
      };
    }

    case actionTypes.REMOVE_CHARITIES_SUCCESS: {
      
      return {
        ...state,
        isCharitiesRemoveSuccess: true
      };
    }

    case actionTypes.REMOVE_CHARITIES_FAIL: {
      return {
        ...state,
        isCharitiesRemoveFail: true
      };
    }

    case actionTypes.RESTORE_CHARITIES: {
      return {
        ...state,
        isCharitiesRestoreSuccess: false,
        isCharitiesRestoreFail: false,
      };
    }

    case actionTypes.RESTORE_CHARITIES_SUCCESS: {
      
      return {
        ...state,
        isCharitiesRestoreSuccess: true
      };
    }

    case actionTypes.RESTORE_CHARITIES_FAIL: {
      return {
        ...state,
        isCharitiesRestoreFail: true
      };
    }
    

    case actionTypes.GET_DONATION_RECORD: {
      let donationRecordList = cloneDeep(state.donationRecordList);
      donationRecordList.exportSuccess = false 
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        donationRecordList.offset = action.data.offset;
        donationRecordList.limit = action.data.limit;
      }

      return {
        ...state,
        donationRecordList,
        isProcessLoadDonationList: true,
      };
    }

    case actionTypes.GET_DONATION_RECORD_SUCCESS: {
      let donationRecordList = cloneDeep(state.donationRecordList);
      
      if(!action.data.data.export)
      {
        donationRecordList.exportSuccess = false          
        donationRecordList.data = action.data.data;
        donationRecordList.meta = {
          page: 1 + state.donationRecordList.offset / state.donationRecordList.limit,
          pageSize: state.donationRecordList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.donationRecordList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        donationRecordList.exportSuccess = true
      }
      
      return {
        ...state,
        isProcessLoadDonationList: false,
        donationRecordList
      };
    }

    case actionTypes.GET_DONATION_RECORD_FAIL: {
      return {
        ...state,
        isProcessLoadDonationList: false,
      };
    }


    case actionTypes.GET_DONATION_DETAIL: {
      let donationDetail = cloneDeep(state.donationDetail);
      
      return {
        ...state,
        donationDetail,
        isProcessLoadDonationDetail: true,
      };
    }

    case actionTypes.GET_DONATION_DETAIL_SUCCESS: {
      return {
        ...state,
        donationDetail: action.data,
        isProcessLoadDonationDetail: false,
      };
    }

    case actionTypes.GET_DONATION_DETAIL_FAIL: {
      return {
        ...state,
        isProcessLoadDonationDetail: false,
      };
    }
    /* ===== Donation ===== */



    default:
      return state;
  }
}
