import * as actionTypes from "./actionTypes";


export const getRedemptionItemCount = (data) => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_COUNT,
    data
  };
};

export const getRedemptionItemCountSuccess = (data) => {

  return {
    type: actionTypes.GET_REDEMPTION_ITEM_COUNT_SUCCESS,
    data
  };
};


export const getRedemptionItemCountFail = () => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_COUNT_FAIL
  };
};


export const getRedemptionItemLoad = (data) => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_LOAD,
    data
  };
};

export const getRedemptionItemLoadSuccess = (data) => {

  return {
    type: actionTypes.GET_REDEMPTION_ITEM_LOAD_SUCCESS,
    data
  };
};


export const getRedemptionItemLoadFail = () => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_LOAD_FAIL
  };
};


export const getRedemptionItemLoadAll = (data) => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_LOAD_ALL,
    data
  };
};

export const getRedemptionItemLoadAllSuccess = (data) => {

  return {
    type: actionTypes.GET_REDEMPTION_ITEM_LOAD_ALL_SUCCESS,
    data
  };
};


export const getRedemptionItemLoadAllFail = () => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_LOAD_ALL_FAIL
  };
};


export const getRedemptionItemDetail = (id, data) => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_DETAIL,
    id,
    data
  };
};

export const getRedemptionItemDetailSuccess = (data) => {

  return {
    type: actionTypes.GET_REDEMPTION_ITEM_DETAIL_SUCCESS,
    data
  };
};


export const getRedemptionItemDetailFail = () => {
  return {
    type: actionTypes.GET_REDEMPTION_ITEM_DETAIL_FAIL
  };
};


export const createRedemptionItem = (data) => {
  return {
    type: actionTypes.CREATE_REDEMPTION_ITEM,
    data
  };
};

export const createRedemptionItemSuccess = () => {

  return {
    type: actionTypes.CREATE_REDEMPTION_ITEM_SUCCESS,
  };
};


export const createRedemptionItemFail = () => {
  return {
    type: actionTypes.CREATE_REDEMPTION_ITEM_FAIL
  };
};


export const updateRedemptionItem = (id, data) => {
  return {
    type: actionTypes.UPDATE_REDEMPTION_ITEM,
    id,
    data
  };
};

export const updateRedemptionItemSuccess = () => {

  return {
    type: actionTypes.UPDATE_REDEMPTION_ITEM_SUCCESS,
  };
};


export const updateRedemptionItemFail = () => {
  return {
    type: actionTypes.UPDATE_REDEMPTION_ITEM_FAIL
  };
};


export const publishRedemptionItem = (id, data) => {
  return {
    type: actionTypes.PUBLISH_REDEMPTION_ITEM,
    id,
    data
  };
};

export const publishRedemptionItemSuccess = () => {

  return {
    type: actionTypes.PUBLISH_REDEMPTION_ITEM_SUCCESS,
  };
};


export const publishRedemptionItemFail = () => {
  return {
    type: actionTypes.PUBLISH_REDEMPTION_ITEM_FAIL
  };
};


export const removeRedemptionItem = (id, data) => {
  return {
    type: actionTypes.REMOVE_REDEMPTION_ITEM,
    id,
    data
  };
};

export const removeRedemptionItemSuccess = () => {

  return {
    type: actionTypes.REMOVE_REDEMPTION_ITEM_SUCCESS,
  };
};


export const removeRedemptionItemFail = () => {
  return {
    type: actionTypes.REMOVE_REDEMPTION_ITEM_FAIL
  };
};




export const redemptionRecordList = (data) => {
  return {
    type: actionTypes.GET_REDEMPTION_RECORD,
    data
  };
};

export const redemptionRecordListSuccess = (data) => {

  return {
    type: actionTypes.GET_REDEMPTION_RECORD_SUCCESS,
    data
  };
};


export const redemptionRecordListFail = () => {
  return {
    type: actionTypes.GET_REDEMPTION_RECORD_FAIL
  };
};


export const loadDcoinTransactionCount = (data) => {
  return {
    type: actionTypes.DCOIN_TRANSACTION_COUNT,
    data
  };
};

export const loadDcoinTransactionCountSuccess = data => {
  return {
    type: actionTypes.DCOIN_TRANSACTION_COUNT_SUCCESS,
    data
  };
};

export const loadDcoinTransactionCountFail = () => {
  return {
    type: actionTypes.DCOIN_TRANSACTION_COUNT_FAIL
  };
};

export const dcoinTransactionList = (data) => {
  return {
    type: actionTypes.DCOIN_TRANSACTION_LIST,
    data
  };
};

export const dcoinTransactionListSuccess = (data) => {

  return {
    type: actionTypes.DCOIN_TRANSACTION_LIST_SUCCESS,
    data
  };
};


export const dcoinTransactionListFail = () => {
  return {
    type: actionTypes.DCOIN_TRANSACTION_LIST_FAIL
  };
};

