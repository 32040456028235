import React, { Component } from "react";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  /* ===== General ===== */
  {
    path: "",
    component: asyncComponent(() => import("../Page/Home"))
  },
  {
    path: "verification-code",
    component: asyncComponent(() => import("../Page/VerificationCode")),
    permission: "_manageVerificationCode"
  },
  {
    path: "blank",
    component: asyncComponent(() => import("../Page/Blank"))
  },
  /* ===== END General ===== */

  /* ===== User ===== */
  {
    path: "individual/user",
    component: asyncComponent(() => import("../Page/UserIndividual")),
    permission: "_manageUserAccount"
  },
  {
    path: "organization/user",
    component: asyncComponent(() => import("../Page/UserOrganization")),
    permission: "_manageUserAccount"
  },
  {
    path: "user/detail/:id",
    component: asyncComponent(() => import("../Page/UserDetail")),
    permission: "_manageUserAccount"
  },
  {
    path: "user/waitingApproval",
    component: asyncComponent(() => import("../Page/UserWaitingApproval")),
    permission: "_manageUserAccount"
  },
  {
    path: "user/history/:id?",
    component: asyncComponent(() => import("../Page/OrderHistory")),
    permission: "_manageUserOrderHistory"
  },
  
  /* ===== END User ===== */

  /* ===== Organization ===== */
  {
    path: "organization",
    component: asyncComponent(() => import("../Page/Organization")),
    permission: "_manageOrganziation"
  },
  {
    path: "organization/:id/detail",
    component: asyncComponent(() => import("../Page/OrganizationDetail")),
    permission: "_manageOrganziation"
  },
  {
    path: "organization/:id/edit",
    component: asyncComponent(() => import("../Page/OrganizationEdit")),
    permission: "_manageOrganziation"
  },
  {
    path: "organization/add/:id",
    component: asyncComponent(() => import("../Page/OrganizationAdd")),
    permission: "_manageOrganziation"
  },  
  /* ===== END Organization ===== */

  /* ===== Admin ===== */
  {
    path: "admin",
    component: asyncComponent(() => import("../Page/Admin")),
    permission: "_manageAdminAcount"
  },
  {
    path: "admin/profile",
    component: asyncComponent(() => import("../Page/AdminProfile")),
    permission: "_manageAdminAcount"
  },
  {
    path: "admin/edit/:id",
    component: asyncComponent(() => import("../Page/AdminEdit")),
    permission: "_manageAdminAcount"
  },
  {
    path: "admin/create",
    component: asyncComponent(() => import("../Page/AdminCreate")),
    permission: "_manageAdminAcount"
  },
  
  /* ===== END Admin ===== */

  /* ===== Admin Privileges===== */
  {
    path: "admin/privileges",
    component: asyncComponent(() => import("../Page/AdminPrivileges")),
    permission: "_manageAdminPrivileges"
  },
  /* ===== END Admin ===== */

  /* ===== Driver ===== */
  {
    path: "individual/driver",
    component: asyncComponent(() => import("../Page/DriverIndividual")),
    permission: "_manageDriverAccount"
  },
  {
    path: "organization/driver",
    component: asyncComponent(() => import("../Page/DriverOrganization")),
    permission: "_manageDriverAccount"
  },
  {
    path: "driver/detail/:id",
    component: asyncComponent(() => import("../Page/DriverDetail")),
    permission: "_manageDriverAccount"
  },
  {
    path: "driver/incentive/:id?",
    component: asyncComponent(() => import("../Page/DriverIncentive")),
    permission: "_manageDriverIncentive"
  },
  {
    path: "driver/dumpster",
    component: asyncComponent(() => import("../Page/DriverDumpster")),
    permission: "_manageDumpsterListing"
  },
  {
    path: "driver/lorry",
    component: asyncComponent(() => import("../Page/DriverLorry")),
    permission: "_manageLorryListing"
  },
  {
    path: "driver/lorry/:id",
    component: asyncComponent(() => import("../Page/DriverLorryDetail")),
    permission: "_manageLorryListing"
  },
  {
    path: "finance/driverCutoff",
    component: asyncComponent(() => import("../Page/DriverCutOff")),
    permission: "_manageDriverCutOff"
  },

  /* ===== Gader ===== */
  {
    path: "grader",
    component: asyncComponent(() => import("../Page/Grader")),
    permission: "_manageGraderAccount"
  },
  {
    path: "grader/detail/:id",
    component: asyncComponent(() => import("../Page/GraderDetail")),
    permission: "_manageGraderAccount"
  },
  
  {
    path: "wallet",
    component: asyncComponent(() => import("../Page/WalletAccount")),
    permission: "_manageWalletAccount"
  },
  
  /* ===== Order ===== */
  {
    path: "driver/transportLicense",
    component: asyncComponent(() => import("../Page/DriverDocumentTransportLicense")),
    permission: "_manageDriverTransportLicense"
  },
  {
    path: "driver/identityCard",
    component: asyncComponent(() => import("../Page/DriverDocumentIdentityCard")),
    permission: "_manageDriverDocumentation"
  },
  {
    path: "driver/drivingLicense",
    component: asyncComponent(() => import("../Page/DriverDocumentDrivingLicense")),
    permission: "_manageDriverDocumentation"
  },
  {
    path: "driver/vocationalLicense",
    component: asyncComponent(() => import("../Page/DriverDocumentVocationalLicense")),
    permission: "_manageDriverDocumentation"
  },
  {
    path: "driver/ssm",
    component: asyncComponent(() => import("../Page/DriverDocumentSSM")),
    permission: "_manageDriverDocumentation"
  },
  {
    path: "driver/lampiranB",
    component: asyncComponent(() => import("../Page/DriverDocumentLampiranB")),
    permission: "_manageDriverDocumentation"
  },
  {
    path: "driver/lorryRoadTax",
    component: asyncComponent(() => import("../Page/DriverDocumentLorryRoadTax")),
    permission: "_manageDriverDocumentation"
  },
  {
    path: "driver/lorryInsurance",
    component: asyncComponent(() => import("../Page/DriverDocumentLorryInsurance")),
    permission: "_manageDriverDocumentation"
  },
  {
    path: "driver/lorryPermit",
    component: asyncComponent(() => import("../Page/DriverDocumentLorryPermit")),
    permission: "_manageDriverDocumentation"
  },
  
  /* ===== END Driver ===== */
  
  /* ===== Order ===== */
  {
    path: "order",
    component: asyncComponent(() => import("../Page/Order")),
    permission: "_manageOrder"
  },
  {
    path: "express/order",
    component: asyncComponent(() => import("../Page/ExpressOrder")),
    permission: "_manageOrder"
  },
  {
    path: "order/dashboard/waste",
    component: asyncComponent(() => import("../Page/OrderDashboardWaste")),
    permission: "_manageOrderDashboard"
  },
  {
    path: "order/dashboard/recycling",
    component: asyncComponent(() => import("../Page/OrderDashboardRecycling")),
    permission: "_manageOrderDashboard"
  },
  {
    path: "order/dashboard/express",
    component: asyncComponent(() => import("../Page/OrderDashboardExpress")),
    permission: "_manageOrderDashboard"
  },
  {
    path: "order/dashboard/scheduledWaste",
    component: asyncComponent(() => import("../Page/OrderDashboardScheduledWaste")),
    permission: "_manageOrderDashboard"
  },
  {
    path: "order/create",
    component: asyncComponent(() => import("../Page/OrderCreate")),
    permission: "_manageOrder"
  },
  {
    path: "order/detail/:id",
    component: asyncComponent(() => import("../Page/OrderDetail")),
    permission: "_manageOrder"
  },
  {
    path: "express/detail/:id",
    component: asyncComponent(() => import("../Page/ExpressOrderDetail")),
    permission: "_manageOrder"
  },
  {
    path: "order/trip",
    component: asyncComponent(() => import("../Page/OrderTrip")),
    permission: "_manageOrderTrip"
  },
  {
    path: "order/trip/calendar",
    component: asyncComponent(() => import("../Page/OrderTripCalendar")),
    permission: "_manageOrderTrip"
  },
  {
    path: "order/collectionRequest",
    component: asyncComponent(() => import("../Page/OrderCollectionRequest")),
    permission: "_manageCollectionRequest"
  },
  {
    path: "order/fillingExtensionRequest",
    component: asyncComponent(() => import("../Page/OrderFillingExtensionRequest")),
    permission: "_manageFillingExtensionRequest"
  },
  {
    path: "order/addonTripRequest",
    component: asyncComponent(() => import("../Page/OrderAddonTripRequest")),
    permission: "_manageAddonTripRequest"
  },
  {
    path: "order/madeItTodayRequest",
    component: asyncComponent(() => import("../Page/OrderMadeItTodayRequest")),
    permission: "_manageMadeItTodayRequest"
  },
  
  /* ===== END Order ===== */

  /* ===== Finance ===== */

  {
    path: "finance/withdrawRequest",
    component: asyncComponent(() => import("../Page/WithdrawalRequest")),
    permission: "_manageWithdrawalRequest"
  },
  {
    path: "finance/walletTransaction",
    component: asyncComponent(() => import("../Page/WalletTransaction")),
    permission: "_manageWalletTransaction"
  },
  {
    path: "finance/dcoinTransaction",
    component: asyncComponent(() => import("../Page/DcoinTransaction")),
    permission: "_manageDcoinTransaction"
  },
  {
    path: "finance/driverPayment",
    component: asyncComponent(() => import("../Page/DriverPayment")),
    permission: "_manageDriverPendingPayment"
  },
  {
    path: "finance/orderCancelRefund",
    component: asyncComponent(() => import("../Page/OrderCancelRefund")),
    permission: "_manageCancelledRefund"
  },
  {
    path: "finance/fpxTransaction",
    component: asyncComponent(() => import("../Page/Transaction")),
    permission: "_manageFPXTransaction"
  },
  {
    path: "finance/duitNowTransaction",
    component: asyncComponent(() => import("../Page/TransactionDuitNow")),
    permission: "_manageDuitNowTransaction"
  },
  {
    path: "referralBonus",
    component: asyncComponent(() => import("../Page/ReferralCommission")),
    permission: "_manageReferralCommission"
  },
  {
    path: "referralBonus/detail/:id",
    component: asyncComponent(() => import("../Page/ReferralCommissionDetail")),
    permission: "_manageReferralCommission"
  },
  
  /* ===== END Finance ===== */
  {
    path: "billing/invoice",
    component: asyncComponent(() => import("../Page/BillingInvoice")),
    permission: "_manageInvoice"
  },
  {
    path: "billing/specialInvoice",
    component: asyncComponent(() => import("../Page/BillingSpecialInvoice")),
    permission: "_manageInvoice"
  },
  {
    path: "billing/dcnote",
    component: asyncComponent(() => import("../Page/BillingDebitCreditNote")),
    permission: "_manageDebitCreditNote"
  },
  {
    path: "billing/purchasebill/DumpRecycling",
    component: asyncComponent(() => import("../Page/BillingPurchaseBillDumpRecycling")),
    permission: "_managePurchaseBill"
  },
  {
    path: "billing/purchasebill/DumpExpress",
    component: asyncComponent(() => import("../Page/BillingPurchaseBillDumpExpress")),
    permission: "_managePurchaseBill"
  },
  {
    path: "billing/recyclableSalesInvoice",
    component: asyncComponent(() => import("../Page/BillingRecyclableSalesInvoice")),
    permission: "_manageRecyclableSalesInvoice"
  },
  {
    path: "billing/MPRMProcess",
    component: asyncComponent(() => import("../Page/MPRMProcess")),
    permission: "_manageMPRMProcess"
  },
  {
    path: "billing/storeReceipt/DumpRecycling",
    component: asyncComponent(() => import("../Page/BillingStoreReceiptDumpRecycling")),
    permission: "_manageStoreReceipt"
  },
  {
    path: "billing/storeReceipt/DumpExpress",
    component: asyncComponent(() => import("../Page/BillingStoreReceiptDumpExpress")),
    permission: "_manageStoreReceipt"
  },
  {
    path: "billing/wasteReceipt/DumpWaste",
    component: asyncComponent(() => import("../Page/BillingWasteReceiptDumpWaste")),
    permission: "_manageWasteReceipt"
  },
  {
    path: "billing/packingHistory/express",
    component: asyncComponent(() => import("../Page/BillingExpressPackingHistoryList")),
    permission: "_managePackingList"
  },
  {
    path: "billing/packingHistory/recycling",
    component: asyncComponent(() => import("../Page/BillingRecyclingPackingHistoryList")),
    permission: "_managePackingList"
  },
  {
    path: "billing/packingHistory/:service/:id",
    component: asyncComponent(() => import("../Page/BillingPackingListDetail")),
    permission: "_managePackingList"
  },
  
  /* ===== Donation ===== */
    
  {
    path: "donation/charites",
    component: asyncComponent(() => import("../Page/Charities")),
    permission: "_manageCharities"
  },
  {
    path: "donation/transaction",
    component: asyncComponent(() => import("../Page/CharitiesDonation")),
    permission: "_manageDonationTransaction"
  },
  /* ===== Donation ===== */
  
  /* ===== DcoinRewards ===== */
    
  {
    path: "DCoinReward/RedemptionItem",
    component: asyncComponent(() => import("../Page/RedemptionItem")),
    permission: "_manageRedemptionItem"
  },
  {
    path: "DCoinReward/RedemptionRecord",
    component: asyncComponent(() => import("../Page/RedemptionRecord")),
    permission: "_manageDcoinRewards"
  },
  /* ===== Donation ===== */

  
  /* ===== Grading ===== */
  {
    path: "grading/approval",
    component: asyncComponent(() => import("../Page/Grading")),
    permission: "_approveGrading"
  },
  {
    path: "grading/pending",
    component: asyncComponent(() => import("../Page/GradingPending")),
    permission: "_manageGrading"
  },
  {
    path: "grading/waste/pending",
    component: asyncComponent(() => import("../Page/GradingWastePending")),
    permission: "_manageGrading"
  },
  
  /* ===== END Grading ===== */

  /* ===== DOE Registration ===== */
  {
    path: "DOERegistration",
    component: asyncComponent(() => import("../Page/DOERegistration")),
    permission: "_manageDOERegistration"
  },
  /* ===== END DOE Registration ===== */
  
  /* ===== Driver Track ===== */
  {
    path: "track",
    component: asyncComponent(() => import("../Page/TrackDriver")),
    permission: "_manageTrackDriver"
  },
  /* ===== END Driver Track ===== */

  /* ===== Lorry ===== */
  {
    path: "lorry",
    component: asyncComponent(() => import("../Page/Lorry")),
    permission: "_manageLorrySetup"
  },
  /* ===== END Lorry ===== */

  /* ===== Dumpster ===== */
  {
    path: "dumpster/service",
    component: asyncComponent(() => import("../Page/DumpsterService")),
    permission: "_manageServiceSetup"
  },
  {
    path: "dumpster/cubic",
    component: asyncComponent(() => import("../Page/DumpsterCubic")),
    permission: "_manageDumpsterSetup"
  },
  {
    path: "dumpster/waste/price",
    component: asyncComponent(() => import("../Page/DumpsterPrice")),
    permission: "_manageServicePriceSetup"
  },
  {
    path: "dumpster/waste/transportfee",
    component: asyncComponent(() => import("../Page/DumpsterTransportFee")),
    permission: "_manageWasteTransportFeeSetup"
  },
  {
    path: "dumpster/waste/process",
    component: asyncComponent(() => import("../Page/DumpsterWasteProcessFee")),
    permission: "_manageWasteTransferCost"
  },
  {
    path: "dumpster/waste/quotation",
    component: asyncComponent(() => import("../Page/DumpWasteQuotation")),
    permission: "_manageDumpWasteQuotation"
  },
  {
    path: "dumpster/recycling/quotation",
    component: asyncComponent(() => import("../Page/DumpRecyclingQuotation")),
    permission: "_manageDumpRecyclingQuotation"
  },
  {
    path: "dumpster/express/quotation",
    component: asyncComponent(() => import("../Page/DumpExpressQuotation")),
    permission: "_manageDumpExpressQuotation"
  },
  {
    path: "dumpster/recycling/price/set",
    component: asyncComponent(() => import("../Page/DumpsterPriceRecycling")),
    permission: "_manageDumpsterRentalCharges"
  },
  {
    path: "dumpster/recycling/price/list",
    component: asyncComponent(() => import("../Page/DumpsterPriceRecycling")),
    permission: "_viewDumpsterRentalCharges"
  },
  {
    path: "dumpster/recycling/process",
    component: asyncComponent(() => import("../Page/DumpsterRecyclingProcessFee")),
    permission: "_manageRecyclableTransferFee"
  },
  /* ===== END Dumpster ===== */

  /* ===== Recycling ===== */
  {
    path: "recycling/category",
    component: asyncComponent(() => import("../Page/RecyclingCategory")),
    permission: "_manageRecyclableCategory"
  },
  {
    path: "recycling/price",
    component: asyncComponent(() => import("../Page/RecyclingPrice")),
    permission: "_manageRecyclablePrice"
  },
  {
    path: "recycling/price/control",
    component: asyncComponent(() => import("../Page/RecyclingPriceControlBoard")),
    permission: "_manageRecyclablePriceControl"
  },
  {
    path: "recycling/extraFee",
    component: asyncComponent(() => import("../Page/RecyclingExtraFee")),
    permission: "_manageRecyclingExtraFee"
  },
  {
    path: "recycling/dumpster",
    component: asyncComponent(() => import("../Page/RecyclingDumpster")),
    permission: "_manageServiceSetup"
  },
  {
    path: "recycling/salesPrice",
    component: asyncComponent(() => import("../Page/RecyclingSalesPrice")),
    permission: "_manageRecyclingPoint"
  },
  {
    path: "recycling/salesPriceUpdate",
    component: asyncComponent(() => import("../Page/RecyclingSalesPriceUpdateHistory")),
    permission: "_manageRecyclablePriceControl"
  },
  
  /* ===== END Recycling ===== */

  /* ===== Express ===== */
  {
    path: "express/price",
    component: asyncComponent(() => import("../Page/ExpressRecyclingPrice")),
    permission: "_manageExpressRecyclablePrice"
  },
  {
    path: "express/price/control",
    component: asyncComponent(() => import("../Page/ExpressRecyclingPriceControlBoard")),
    permission: "_manageExpressRecyclablePriceControl"
  },
  {
    path: "express/salesPriceUpdate",
    component: asyncComponent(() => import("../Page/RecyclingSalesPriceUpdateHistoryExpress")),
    permission: "_manageExpressRecyclablePriceControl"
  },
  
  /* ===== END Express ===== */

  /* ===== Scheduled Waste ===== */  
  {
    path: "scheduled_waste/waste",
    component: asyncComponent(() => import("../Page/ScheduledWaste")),
    permission: "_manageScheduledWasteSetup"
  },
  {
    path: "scheduled_waste/price/set",
    component: asyncComponent(() => import("../Page/ScheduledWastePriceControl")),
    permission: "_manageScheduledWastePriceSetup"
  },
  {
    path: "scheduled_waste/price/list",
    component: asyncComponent(() => import("../Page/ScheduledWastePriceControl")),
    permission: "_viewScheduledWastePrice"
  },
  {
    path: "scheduled_waste/lorry/price/set",
    component: asyncComponent(() => import("../Page/ScheduledWasteLorryPrice")),
    permission: "_manageScheduledWasteTransportFeeSetup"
  },
  {
    path: "scheduled_waste/lorry/price/list",
    component: asyncComponent(() => import("../Page/ScheduledWasteLorryPrice")),
    permission: "_viewScheduledWasteTransportFee"
  },
  {
    path: "scheduled_waste/container",
    component: asyncComponent(() => import("../Page/ScheduledWasteContainer")),
    permission: "_manageScheduledWasteContainer"
  },
  {
    path: "scheduled_waste/processCost",
    component: asyncComponent(() => import("../Page/ScheduledWasteProcessFee")),
    permission: "_manageScheduledWasteTransferCost"
  },
  
  /* ===== END Scheduled Waste ===== */
  

  /* ===== Scheduled Recovery Company ===== */  
  {
    path: "scheduled_waste/recoverer",
    component: asyncComponent(() => import("../Page/ScheduledRecoveryCompany")),
    permission: "_manageRecoveryCompany"
  },
  /* ===== END Scheduled Recovery Company ===== */

  /* ===== Landfill Point ===== */  
  {
    path: "point/landfill",
    component: asyncComponent(() => import("../Page/LandfillPoint")),
    permission: "_manageDisposalPoint"
  },
  {
    path: "point/landfill/recycling",
    component: asyncComponent(() => import("../Page/LandfillPointRecycling")),
    permission: "_manageRecyclingPoint"
  },
  {
    path: "point/landfill/adjustment",
    component: asyncComponent(() => import("../Page/LandfillAdjustment")),
    permission: "_manageLandfillFee"
  },
  {
    path: "point/landfill/adjustment/recycling",
    component: asyncComponent(() => import("../Page/LandfillAdjustmentRecycling")),
    permission: "_manageRecyclingPointAdjustment"
  },
  {
    path: "point/landfill/adjustment/detail/:id",
    component: asyncComponent(() => import("../Page/LandfillAdjustmentDetail")),
    permission: "_manageLandfillFee"
  },
  {
    path: "point/landfill/adjustment/order/:id",
    component: asyncComponent(() => import("../Page/LandfillAdjustmentAffectedOrder")),
    permission: "_manageLandfillFee"
  },
  {
    path: "point/landfill/adjustment/recycling/detail/:id",
    component: asyncComponent(() => import("../Page/LandfillAdjustmentRecyclingDetail")),
    permission: "_manageRecyclingPointAdjustment"
  },
  {
    path: "point/landfill/adjustment/recycling/order/:id",
    component: asyncComponent(() => import("../Page/LandfillAdjustmentRecyclingAffectedOrder")),
    permission: "_manageRecyclingPointAdjustment"
  },
  /* ===== END Landfill Point ===== */


  /* ===== Feedback ===== */  
  {
    path: "feedback",
    component: asyncComponent(() => import("../Page/Feedback")),
    permission: "_manageFeedback"
  },
  /* ===== END Feedback ===== */
  

  /* ===== Merchant Registration ===== */  
  {
    path: "merchantRegistration",
    component: asyncComponent(() => import("../Page/MerchantRegistration")),
    permission: "_managePartner"
  },
  /* ===== END Merchant Registration ===== */


  /* ===== Promotion ===== */
  {
    path: "promotion",
    component: asyncComponent(() => import("../Page/Promotion")),
    permission: "_managePromotion"
  },
  /* ===== END Promotion ===== */
  
  /* ===== Settings ===== */  
  {
    path: "settings",
    component: asyncComponent(() => import("../Page/Settings")),
    permission: "_manageMasterSetup"
  },
  {
    path: "order_type",
    component: asyncComponent(() => import("../Page/OrderType")),
    permission: "_manageMasterSetup"
  },
  {
    path: "distance",
    component: asyncComponent(() => import("../Page/Distance")),
    permission: "_manageMasterSetup"
  },
  {
    path: "carbonFootprint",
    component: asyncComponent(() => import("../Page/CarbonFootprintSettings")),
    permission: "_manageMasterSetup"
  },
  {
    path: "referralBonusControl",
    component: asyncComponent(() => import("../Page/ReferralCommissionControl")),
    permission: "_manageMasterSetup"
  },
  {
    path: "inviteFriendSetting",
    component: asyncComponent(() => import("../Page/InviteFriendSettings")),
    permission: "_manageMasterSetup"
  },
  {
    path: "driverSubscriptionSettings",
    component: asyncComponent(() => import("../Page/DriverSubscriptionSettings")),
    permission: "_manageMasterSetup"
  },
  
  /* ===== END Settings ===== */

  /* ===== Notification ===== */
  {
    path: "notification",
    component: asyncComponent(() => import("../Page/Notification")),
    permission: "_manageNotification"
  },
  {
    path: "notification/create",
    component: asyncComponent(() => import("../Page/NotificationCreate")),
    permission: "_manageNotification"
  },
  {
    path: "notification/edit/:id",
    component: asyncComponent(() => import("../Page/NotificationEdit")),
    permission: "_manageNotification"
  },
  /* ===== END Notification ===== */

  /* ===== Whatsapp Notification ===== */
  {
    path: "whatsapp",
    component: asyncComponent(() => import("../Page/WhatsappNotification")),
    permission: "_manageWhatsapp"
  },
  /* ===== END Whatsapp Notification ===== */

  /* ===== Reports ===== */
  {
    path: "reporting/wasteSummary",
    component: asyncComponent(() => import("../Page/WasteCollectionSummary")),
    permission: "_manageWasteCollectionReport"
  },
  {
    path: "reporting/recyclableSummary",
    component: asyncComponent(() => import("../Page/RecyclableCollectionSummary")),
    permission: "_manageRecyclableCollectionReport"
  },
  {
    path: "reporting/scheduledWasteSummary",
    component: asyncComponent(() => import("../Page/ScheduledWasteCollectionSummary")),
    permission: "_manageScheduledWasteCollectionReport"
  },   
  {
    path: "reporting/landfillSummary",
    component: asyncComponent(() => import("../Page/LandfillSummary")),
    permission: "_manageLandfillReport"
  },
  {
    path: "reporting/recyclingStockSummary",
    component: asyncComponent(() => import("../Page/MonthlyStockSummaryRecycling")),
    permission: "_manageMonthlyStockSummaryReport"
  },
  {
    path: "reporting/expressStockSummary",
    component: asyncComponent(() => import("../Page/MonthlyStockSummaryExpress")),
    permission: "_manageMonthlyStockSummaryReport"
  },
  {
    path: "reporting/wasteStockSummary",
    component: asyncComponent(() => import("../Page/MonthlyWasteSummaryWaste")),
    permission: "_manageMonthlyStockSummaryReport"
  },
  {
    path: "reporting/recyclingPurchaseBillStatement",
    component: asyncComponent(() => import("../Page/PurchaseBillStatementRecycling")),
    permission: "_manageMonthlyPurchaseBillStatement"
  }, 
  {
    path: "reporting/expressPurchaseBillStatement",
    component: asyncComponent(() => import("../Page/PurchaseBillStatementExpress")),
    permission: "_manageMonthlyPurchaseBillStatement"
  }, 
  {
    path: "reporting/driverTripSummary",
    component: asyncComponent(() => import("../Page/DriverTripSummary")),
    permission: "_manageDriverTripSummaryReport"
  },
  {
    path: "reporting/orderDistanceSummary",
    component: asyncComponent(() => import("../Page/OrderDistanceSummary")),
    permission: "_manageOrderDistanceSummaryReport"
  },
  {
    path: "reporting/referralBonusSummary",
    component: asyncComponent(() => import("../Page/ReferralCommissionSummary")),
    permission: "_manageReferralCommissionSummary"
  },  
  {
    path: "dashboard",
    component: asyncComponent(() => import("../Page/Dashboard")),
    permission: "_manageDashboard"
  },  
  {
    path: "dashboard/chart",
    component: asyncComponent(() => import("../Page/DashboardChart")),
    permission: "_manageDashboard"
  },
  {
    path: "dashboard/impact",
    component: asyncComponent(() => import("../Page/DashboardImpact")),
    permission: "_manageDashboard"
  },
  /* ===== END Reports ===== */
  
  /* ===== Accounting Reports ===== */
  {
    path: "reporting/dumpWasteInvoiceSummary",
    component: asyncComponent(() => import("../Page/SummaryDumpWasteInvoice")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/dumpRecyclingInvoiceSummary",
    component: asyncComponent(() => import("../Page/SummaryDumpRecyclingInvoice")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/scheduledWasteInvoiceSummary",
    component: asyncComponent(() => import("../Page/SummaryDumpScheduledWasteInvoice")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/fillingExtensionInvoiceSummary",
    component: asyncComponent(() => import("../Page/SummaryFillingExtentionInvoice")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/addOnTripInvoiceSummary",
    component: asyncComponent(() => import("../Page/SummaryAddOnTripInvoice")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/dumpRecyclingPurchaseBillSummary",
    component: asyncComponent(() => import("../Page/SummaryPurchaseBillInvoiceDumpRecycling")),
    permission: "_manageAccountingReportDRPurchaseBill"
  },
  {
    path: "reporting/dumpExpressPurchaseBillSummary",
    component: asyncComponent(() => import("../Page/SummaryPurchaseBillInvoiceDumpExpress")),
    permission: "_manageAccountingReportDEPurchaseBill"
  },
  {
    path: "reporting/dumpRecyclingStoreReceiptSummary",
    component: asyncComponent(() => import("../Page/SummaryStoreReceiptDumpRecycling")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/dumpExpressStoreReceiptSummary",
    component: asyncComponent(() => import("../Page/SummaryStoreReceiptDumpExpress")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/dumpWasteWasteReceiptSummary",
    component: asyncComponent(() => import("../Page/SummaryWasteReceiptDumpWaste")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/recyclableSalesInvoiceSummary",
    component: asyncComponent(() => import("../Page/SummaryRecyclableSalesInvoice")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/driverIncentiveDumpWasteSummary",
    component: asyncComponent(() => import("../Page/SummaryDriverIncentiveDumpWaste")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/driverIncentiveDumpRecyclingSummary",
    component: asyncComponent(() => import("../Page/SummaryDriverIncentiveDumpRecycling")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/driverIncentiveDumpScheduledWasteSummary",
    component: asyncComponent(() => import("../Page/SummaryDriverIncentiveDumpScheduledWaste")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/driverIncentiveFillingExtensionSummary",
    component: asyncComponent(() => import("../Page/SummaryDriverIncentiveFillingExtension")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/driverReimbursementSummary",
    component: asyncComponent(() => import("../Page/SummaryDriverReimbursement")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/bookingFeeSummary",
    component: asyncComponent(() => import("../Page/SummaryBookingFee")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/processFeeSummary",
    component: asyncComponent(() => import("../Page/SummaryProcessFee")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/landfillFeeSummary",
    component: asyncComponent(() => import("../Page/SummaryLandfillFee")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/userLoadingFeeSummary",
    component: asyncComponent(() => import("../Page/SummaryUserLoadingFee")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/driverLoadingPaymentSummary",
    component: asyncComponent(() => import("../Page/SummaryDriverLoadingPayment")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/sortingFeeSummary",
    component: asyncComponent(() => import("../Page/SummarySortingFee")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/oversizeFeeSummary",
    component: asyncComponent(() => import("../Page/SummaryOversizeFee")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/recyclingRecyclablePurchaseSummary",
    component: asyncComponent(() => import("../Page/SummaryRecyclablePurchaseDumpRecycling")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/expressRecyclablePurchaseSummary",
    component: asyncComponent(() => import("../Page/SummaryRecyclablePurchaseDumpExpress")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/specialInvoiceSummary",
    component: asyncComponent(() => import("../Page/SummarySpecialInvoice")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/graderDailySettlement",
    component: asyncComponent(() => import("../Page/GraderDailySettlement")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/packingListDumpExpressSummary",
    component: asyncComponent(() => import("../Page/SummaryPackingListDumpExpress")),
    permission: "_manageAccountingReport"
  },
  {
    path: "reporting/packingListDumpRecyclingSummary",
    component: asyncComponent(() => import("../Page/SummaryPackingListDumpRecycling")),
    permission: "_manageAccountingReport"
  },
  
  /* ===== END Accounting Reports ===== */
  
  {
    path: "version",
    component: asyncComponent(() => import("../Page/VersionUpdate"))
  },
  {
    path: "point/weighing",
    component: asyncComponent(() => import("../Page/WeighingPoint")),
    permission: "_manageWeighingPoint"
  },
  {
    path: "findDriver",
    component: asyncComponent(() => import("../Page/FindDriverSimulate")),
    permission: "_managefindDriverSimulate"
  },
  
];

class AppRouter extends Component {
  render() {
    const { url, style, adminPrivileges } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(data => {
            const { path, exact, ...otherProps } = data;

            let havePermission = true;
            if (data.permission) {
              if (adminPrivileges[data.permission] === false) {
                havePermission = false;
              }
            }

            return havePermission ? (
              <Route
                exact={exact === false ? false : true}
                key={data.path}
                path={`${url}${data.path}`}
                {...otherProps}
              />
            ) : null;
          })}
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AppRouter);
