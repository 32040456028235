import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createBrowserHistory as createHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerReducer, routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";
import sagas from "./sagas";
import logger from "redux-logger";

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

let middlewares = [thunk, sagaMiddleware, routeMiddleware];
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  middlewares = [thunk, sagaMiddleware, routeMiddleware, logger];
}

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: []
};

const AppPersistConfig = {
  key: "App",
  storage,
  whitelist: ["env"]
};

const AuthPersistConfig = {
  key: "Auth",
  storage,
  whitelist: ["jwtToken", "jwtTokenRefresh", "profileData"]
};

const FirebasePersistConfig = {
  key: "FirebaseDB",
  storage,
  whitelist: ["latest_time","pending_count"]
};

const persistedReducer = persistReducer(
  rootPersistConfig,
  combineReducers({
    App: persistReducer(AppPersistConfig, reducers.App),
    Auth: persistReducer(AuthPersistConfig, reducers.Auth),
    Admin: reducers.Admin,
    Dumpster: reducers.Dumpster,
    DumpsterPrice: reducers.DumpsterPrice,
    General: reducers.General,
    UserOrganization: reducers.UserOrganization,
    User: reducers.User,
    Driver: reducers.Driver,
    Lorry: reducers.Lorry,
    Order: reducers.Order,
    Notification: reducers.Notification,
    GoogleMap: reducers.GoogleMap,
    Recycling: reducers.Recycling,
    Payment: reducers.Payment,
    Whatsapp: reducers.Whatsapp,
    ScheduledWaste: reducers.ScheduledWaste,
    Grader: reducers.Grader,
    Express: reducers.Express,
    DCoinReward: reducers.DCoinReward,
    FirebaseDB: persistReducer(FirebasePersistConfig, reducers.FirebaseDB), 
    routerReducer
  })
);

const store = createStore(
  persistedReducer,
  undefined,
  compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(sagas);

const persistor = persistStore(store);

export { store, persistor, history };
