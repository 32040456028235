import React, { Component } from "react";
import clone from "clone";
import {cloneDeep} from "lodash";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import Divider from "antd/lib/divider";

// Redux
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

import Scrollbars from "../../components/utility/customScrollBar.js";
import Logo from "../../components/utility/logo";
import Menu from "../../components/uielements/menu";
import {
  LinkCell,
} from "../../assets/components/Tables/helperCells";

import themes from "../../settings/themes";
import { THEME_CONFIG, SITE_CONFIG, APP_VERSION, SCHEDULED_WASTE_START } from "../../settings";

// Style
import SidebarWrapper from "./sidebar.style";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded_count: false,
      pending_count: {
        individual_driver_waiting_approve: 0,
        organization_driver_waiting_approve: 0,
        user_branch_waiting_approve: 0,
        lorry_waiting_approve: 0,
        user_credit_term_waiting_approve: 0,
        order_pending_accept: 0,
        grading_waiting_approve: 0,
        withdraw_waiting_approve: 0,
        order_refund_waiting_approve: 0,
        driver_payment_waiting_approve: 0,
        reimburse_cut_off_waiting_approve: 0,
        feeback_waiting_resolve: 0,
        partner_manager_waiting_verify: 0,
        transport_license_waiting_verify: 0,
        waiting_set_recovery_company: 0,
        DOE_Registration_waiting_approve: 0,
      }
    };

    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    
    let { loaded_count } = this.state
    var pending_count = cloneDeep(this.state.pending_count)
        
    if (
      JSON.stringify(prevProps.pending_count) !== JSON.stringify(this.props.pending_count) ||
      !loaded_count
    ) {
      console.log(this.props.pending_count)
      
      Object.keys(this.props.pending_count).map(key => {
        
        if(pending_count[key] !== undefined)        
          pending_count[key] = (this.props.pending_count[key]) ? Object.keys(this.props.pending_count[key]).length : 0

        return true;
      })
      
      this.setState( { pending_count, loaded_count: true });
      
    }
  }
  

  handleClick(e) {
    this.props.onChangeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.onToggleCollapsed();
        this.props.onToggleOpenDrawer();
      }, 100);
    }
  }

  onOpenChange(newOpenKeys) {
    const { app, onChangeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    onChangeOpenKeys(nextOpenKeys);
  }

  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"],
      dumpster_dump_waste: ["dumpster"],
      dumpster_recycling: ["dumpster"]
    };
    return map[key] || [];
  };

  render() {
    const { app, onToggleOpenDrawer, height, profileData, quotationCount, newRecyclingPriceCount } = this.props;
    const { pending_count } = this.state;
    const url = stripTrailingSlash(this.props.url);
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = event => {
      if (openDrawer === false) {
        onToggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        onToggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[THEME_CONFIG.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };

    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor
    };
    console.log({pending_count})
    // const subsubmenuStyle = {
    //   backgroundColor: "rgba(0,0,0,0.3)",
    //   paddingTop: 0.1,
    //   color: customizedTheme.textColor
    // };

    const submenuColor = { color: customizedTheme.textColor };
    const adminPrivileges = (profileData.AdminPrivileges) ? profileData.AdminPrivileges : {}
  
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={255}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu menu-top"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              defaultOpenKeys={["sub1"]}
              onOpenChange={this.onOpenChange}
            >
              {adminPrivileges._manageDashboard && (
                <SubMenu
                  key="dashboard"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-analytics" />
                      <span className="nav-text">Dashboard</span>
                    </span>
                  }
                >
                  
                  <Menu.Item style={submenuStyle} key="dashboardSummary" className="submenu">
                    <Link style={submenuColor} to={`${url}/dashboard`}>
                    Dashboard Summary
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="dashboardChart" className="submenu">
                    <Link style={submenuColor} to={`${url}/dashboard/chart`}>
                    Dashboard Chart
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="dashboardImpact" className="submenu">
                    <Link style={submenuColor} to={`${url}/dashboard/impact`}>
                    Dashboard Impact
                    </Link>
                  </Menu.Item>

                </SubMenu>
              )}

              {(adminPrivileges._manageUserAccount || adminPrivileges._manageOrganziation) && (
                <SubMenu
                  key="user"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-people" />
                      <span className="nav-text">User Management
                      
                        {(pending_count.user_branch_waiting_approve > 0 || pending_count.user_credit_term_waiting_approve > 0) && (
                          <span className="badge">({pending_count.user_branch_waiting_approve + pending_count.user_credit_term_waiting_approve})</span>
                        )}     
                      </span>
                    </span>
                  }
                >
                  
                  {adminPrivileges._manageUserAccount && (
                  <Menu.Item style={submenuStyle} key="individualUserList" className="submenu">
                      <Link style={submenuColor} to={`${url}/individual/user`}>
                      Individual User
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageUserAccount && (
                  <Menu.Item style={submenuStyle} key="organizationUserList" className="submenu">
                      <Link style={submenuColor} to={`${url}/organization/user`}>
                        Organization User
                        {pending_count.user_credit_term_waiting_approve > 0 && (
                          <span className="badge">({pending_count.user_credit_term_waiting_approve})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageOrganziation && (
                    <Menu.Item style={submenuStyle} key="organization" className="submenu">
                      <Link style={submenuColor} to={`${url}/organization`}>
                        Organization
                        {pending_count.user_branch_waiting_approve > 0 && (
                          <span className="badge">({pending_count.user_branch_waiting_approve})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}

                  {/* {adminPrivileges._manageUserAccount && (
                    <Menu.Item style={submenuStyle} key="userWaitingApproval">
                      <Link style={submenuColor} to={`${url}/user/waitingApproval`}>
                      Waiting Approval
                      </Link>
                    </Menu.Item>
                  )} */}

                </SubMenu>
              )}

              {(adminPrivileges._manageDriverAccount || (SCHEDULED_WASTE_START && adminPrivileges._manageDriverTransportLicense) || adminPrivileges._manageDriverDocumentation) && (
                <SubMenu
                  key="driver"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-people" />
                      <span className="nav-text">Driver Management
                      
                        {(pending_count.individual_driver_waiting_approve > 0 || pending_count.organization_driver_waiting_approve > 0 || pending_count.transport_license_waiting_verify > 0 || pending_count.waiting_set_recovery_company > 0) && (
                          <span className="badge">({pending_count.individual_driver_waiting_approve + pending_count.organization_driver_waiting_approve + pending_count.transport_license_waiting_verify + pending_count.waiting_set_recovery_company})</span>
                        )}     
                           
                      </span>
                    </span>
                  }
                >
                  
                  {adminPrivileges._manageDriverAccount && (
                    <Menu.Item style={submenuStyle} key="individualDriverList" className="submenu">
                      <Link style={submenuColor} to={`${url}/individual/driver`}>
                        Individual Driver
                        {pending_count.individual_driver_waiting_approve > 0 && (
                          <span className="badge">({pending_count.individual_driver_waiting_approve})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverAccount && (
                    <Menu.Item style={submenuStyle} key="organizationDriverList" className="submenu">
                      <Link style={submenuColor} to={`${url}/organization/driver`}>
                        Organization Driver
                        {pending_count.organization_driver_waiting_approve > 0 && (
                          <span className="badge">({pending_count.organization_driver_waiting_approve})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {SCHEDULED_WASTE_START && adminPrivileges._manageDriverTransportLicense && (
                    <Menu.Item style={submenuStyle} key="driverTransportLicense" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/transportLicense`}>
                        Driver Transport License
                        {(pending_count.transport_license_waiting_verify > 0 || pending_count.waiting_set_recovery_company > 0) && (
                          <span className="badge">({pending_count.transport_license_waiting_verify + pending_count.waiting_set_recovery_company})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverIdentityCard" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/identityCard`}>
                        Driver Identity Card
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverDrivingLicense" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/drivingLicense`}>
                        Driver Driving License
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverVocationalLicense" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/vocationalLicense`}>
                        Driver Vocational License
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverSSM" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/ssm`}>
                        Driver SSM
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverLampiranB" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/lampiranB`}>
                        Driver Lampiran B
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverLorryRoadTax" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/lorryRoadTax`}>
                        Driver Lorry Road Tax
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverLorryInsurance" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/lorryInsurance`}>
                        Driver Lorry Insurance
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverDocumentation && (
                    <Menu.Item style={submenuStyle} key="driverLorryPermit" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/lorryPermit`}>
                        Driver Lorry Permit
                      </Link>
                    </Menu.Item>
                  )}
                  
                  
                </SubMenu>
              )}

              {adminPrivileges._manageGraderAccount && (
                <Menu.Item key="grader">
                  <Link to={`${url}/grader`}>
                    <span className="isoMenuHolder">
                      <i className="ion-ios-people" />
                      <span className="nav-text">Grader Management
                                              
                        {pending_count.grader_waiting_approve > 0 && (
                          <span className="badge">({pending_count.grader_waiting_approve})</span>
                        )} 
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {(adminPrivileges._manageOrderDashboard || adminPrivileges._manageOrder || adminPrivileges._manageCollectionRequest || adminPrivileges._managefindDriverSimulate || adminPrivileges._manageFillingExtensionRequest || adminPrivileges._manageMadeItTodayRequest || adminPrivileges._manageAddonTripRequest) && (
                <SubMenu
                  key="order"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-android-cart" />
                      <span className="nav-text">Order Management
                        {pending_count.order_pending_accept > 0 && (
                          <span className="badge">({pending_count.order_pending_accept})</span>
                        )}                        
                      </span>
                    </span>
                  }
                >
                  {adminPrivileges._manageOrderDashboard && (
                    <Menu.Item style={submenuStyle} key="OrderDashboardWaste" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/dashboard/waste`}>
                       DumpWaste Dashboard
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageOrderDashboard && (
                    <Menu.Item style={submenuStyle} key="OrderDashboardRecycling" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/dashboard/recycling`}>
                       DumpRecycling Dashboard
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageOrderDashboard && (
                    <Menu.Item style={submenuStyle} key="OrderDashboardExpress" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/dashboard/express`}>
                       DumpExpress Dashboard
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {SCHEDULED_WASTE_START && adminPrivileges._manageOrderDashboard && (
                    <Menu.Item style={submenuStyle} key="OrderDashboardScheduledWaste" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/dashboard/scheduledWaste`}>
                       DumpScheduledWaste Dashboard
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageOrder && (
                    <Menu.Item style={submenuStyle} key="orderList" className="submenu">
                      <Link style={submenuColor} to={`${url}/order`}>
                        Other Order Control
                        {pending_count.order_pending_accept > 0 && (
                          <span className="badge">({pending_count.order_pending_accept})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageOrder && (
                    <Menu.Item style={submenuStyle} key="expressOrderList" className="submenu">
                      <Link style={submenuColor} to={`${url}/express/order`}>
                        DumpExpress Order Control
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageCollectionRequest && (
                    <Menu.Item style={submenuStyle} key="OrderCollectionRequest" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/collectionRequest`}>
                      Collection Request
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageFillingExtensionRequest && (
                    <Menu.Item style={submenuStyle} key="OrderFillingExtensionRequest" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/fillingExtensionRequest`}>
                      Filling Extension Request
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageMadeItTodayRequest && (
                    <Menu.Item style={submenuStyle} key="OrderMadeItTodayRequest" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/madeItTodayRequest`}>
                      Made It Today Request
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageAddonTripRequest && (
                    <Menu.Item style={submenuStyle} key="OrderAddonTripRequest" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/addonTripRequest`}>
                      Add On Trip Request
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._managefindDriverSimulate && (
                    <Menu.Item style={submenuStyle} key="findDriverSimulate" className="submenu">
                      <Link style={submenuColor} to={`${url}/findDriver`}>
                      Find Driver Simulator
                      </Link>
                    </Menu.Item>
                  )}
                  </SubMenu>
              )}

              {adminPrivileges._manageOrderTrip && (
                <SubMenu
                  key="orderTrip"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                    <i className="ion-android-drafts" />
                      <span className="nav-text">Trip Management</span>
                    </span>
                  }
                >
                    <Menu.Item style={submenuStyle} key="orderTripTableView" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/trip`}>
                       Table View
                      </Link>
                    </Menu.Item>

                    <Menu.Item style={submenuStyle} key="orderTripCalendarView" className="submenu">
                      <Link style={submenuColor} to={`${url}/order/trip/calendar`}>
                        Calendar View
                      </Link>
                    </Menu.Item>
                    
                  </SubMenu>
              )}

              {adminPrivileges._manageWalletAccount && (
                <Menu.Item key="wallet">
                  <Link to={`${url}/wallet`}>
                    <span className="isoMenuHolder">
                      <i className="ion-ios-people" />
                      <span className="nav-text">Wallet Management
                                              
                        {pending_count.wallet_waiting_approve > 0 && (
                          <span className="badge">({pending_count.wallet_waiting_approve})</span>
                        )} 
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              )}


              {(adminPrivileges._manageInvoice || adminPrivileges._manageDebitCreditNote || adminPrivileges._managePurchaseBill || adminPrivileges._manageRecyclableSalesInvoice || adminPrivileges._manageMPRMProcess  || adminPrivileges._manageMonthlyPurchaseBillStatement || adminPrivileges._manageStoreReceipt || adminPrivileges._manageWasteReceipt || adminPrivileges._managePackingList) && (
                <SubMenu
                  key="billing"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                    <i className="ion-android-drafts" />
                      <span className="nav-text">Billing Management</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageInvoice && (
                    <Menu.Item style={submenuStyle} key="billingInvoice" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/invoice`}>
                       Invoice
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageInvoice && (

                    <Menu.Item style={submenuStyle} key="specialInvoice" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/specialInvoice`}>
                        Special Invoice
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageDebitCreditNote && (

                    <Menu.Item style={submenuStyle} key="billingDebitCreditNote" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/dcnote`}>
                        Debit Credit Note
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._managePurchaseBill && (

                    <Menu.Item style={submenuStyle} key="billingPurchaseBillDumpRecycling" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/purchasebill/DumpRecycling`}>
                        DumpRecycling Purchase Bill
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._managePurchaseBill && (

                    <Menu.Item style={submenuStyle} key="billingPurchaseBillDumpExpress" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/purchasebill/DumpExpress`}>
                        DumpExpress Purchase Bill 
                      </Link>
                    </Menu.Item>
                  )}
                  

                  {adminPrivileges._manageMonthlyPurchaseBillStatement && (
                    <Menu.Item style={submenuStyle} key="recyclingPurchaseBillStatement" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/recyclingPurchaseBillStatement`}>
                        DumpRecycling Purchase Bill Statement
                      </Link>
                    </Menu.Item>
                  )}
                  

                  {adminPrivileges._manageMonthlyPurchaseBillStatement && (
                    <Menu.Item style={submenuStyle} key="expressPurchaseBillStatement" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/expressPurchaseBillStatement`}>
                        DumpExpress Purchase Bill Statement
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageStoreReceipt && (

                    <Menu.Item style={submenuStyle} key="billingStoreReceiptDumpRecycling" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/storeReceipt/DumpRecycling`}>
                        DumpRecycling Store Receipt
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageStoreReceipt && (

                    <Menu.Item style={submenuStyle} key="billingStoreReceiptDumpExpress" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/storeReceipt/DumpExpress`}>
                        DumpExpress Store Receipt
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageWasteReceipt && (

                    <Menu.Item style={submenuStyle} key="billingWasteReceiptDumpWaste" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/wasteReceipt/DumpWaste`}>
                        DumpWaste Waste Receipt
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageRecyclableSalesInvoice && (

                    <Menu.Item style={submenuStyle} key="billingRecyclableSalesInvoice" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/recyclableSalesInvoice`}>
                      Recyclable Sales Invoice
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageMPRMProcess && (
                    <Menu.Item style={submenuStyle} key="MPRMProcess" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/MPRMProcess`}>
                        MPRM Process        
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._managePackingList && (
                    <Menu.Item style={submenuStyle} key="expressPackingHistory" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/packingHistory/express`}>
                        DumpExpress Packing List  
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._managePackingList && (
                    <Menu.Item style={submenuStyle} key="recyclingPackingHistory" className="submenu">
                      <Link style={submenuColor} to={`${url}/billing/packingHistory/recycling`}>
                        DumpRecycling Packing List       
                      </Link>
                    </Menu.Item>
                  )}
                    
                  </SubMenu>
              )}
              

              {(adminPrivileges._manageWalletTransaction || adminPrivileges._manageDcoinTransaction || adminPrivileges._manageWithdrawalRequest || adminPrivileges._manageCancelledRefund || adminPrivileges._manageDriverPendingPayment || adminPrivileges._manageFPXTransaction  || adminPrivileges._manageDuitNowTransaction || adminPrivileges._manageDriverCutOff || adminPrivileges._manageUserOrderHistory || adminPrivileges._manageDriverIncentive) && (
                <SubMenu
                  key="finance"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                    <i className="ion-ios-filing-outline" />
                      <span className="nav-text">Finance
                      
                        {(pending_count.withdraw_waiting_approve > 0 || pending_count.order_refund_waiting_approve > 0 || pending_count.driver_payment_waiting_approve > 0 || pending_count.reimburse_cut_off_waiting_approve > 0) && (
                          <span className="badge">({pending_count.withdraw_waiting_approve + pending_count.order_refund_waiting_approve + pending_count.driver_payment_waiting_approve + pending_count.reimburse_cut_off_waiting_approve})</span>
                        )}     
                      </span>
                    </span>
                  }
                >

                  {adminPrivileges._manageWalletTransaction && (
                    <Menu.Item style={submenuStyle} key="WalletTransaction" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/walletTransaction`}>
                        Wallet Transaction
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageDcoinTransaction && (
                    <Menu.Item style={submenuStyle} key="dcoinTransaction" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/dcoinTransaction`}>
                        Dcoin Transaction
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageWithdrawalRequest && (
                    <Menu.Item style={submenuStyle} key="WithdrawRequest" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/withdrawRequest`}>
                        Withdrawal Request
                        {pending_count.withdraw_waiting_approve > 0 && (
                          <span className="badge">({pending_count.withdraw_waiting_approve})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageCancelledRefund && (
                    <Menu.Item style={submenuStyle} key="OrderCancelRefund" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/orderCancelRefund`}>
                         Cancelled Order Refund   
                        {pending_count.order_refund_waiting_approve > 0 && (
                          <span className="badge">({pending_count.order_refund_waiting_approve})</span>
                        )}                
                      </Link>
                    </Menu.Item>
                  )}

                  
                  {adminPrivileges._manageDriverPendingPayment && (
                    <Menu.Item style={submenuStyle} key="driverPayment" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/driverPayment`}>
                        Driver Job Payment    
                        {pending_count.driver_payment_waiting_approve > 0 && (
                          <span className="badge">({pending_count.driver_payment_waiting_approve})</span>
                        )}         
                      </Link>
                    </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageDriverCutOff && (
                    <Menu.Item style={submenuStyle} key="driverCutOff" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/driverCutoff`}>
                        Driver Reimburse Cut Off
                        {pending_count.reimburse_cut_off_waiting_approve > 0 && (
                          <span className="badge">({pending_count.reimburse_cut_off_waiting_approve})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageFPXTransaction && (
                    <Menu.Item style={submenuStyle} key="FPXTransaction" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/fpxTransaction`}>
                        FPX Transaction         
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageDuitNowTransaction && (
                    <Menu.Item style={submenuStyle} key="DuitNowTransaction" className="submenu">
                      <Link style={submenuColor} to={`${url}/finance/duitNowTransaction`}>
                        DuitNow Transaction         
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageUserOrderHistory && (
                    <Menu.Item style={submenuStyle} key="UserOrderHistory" className="submenu">
                      <Link style={submenuColor} to={`${url}/user/history`}>
                        User Order History        
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageDriverIncentive && (
                    <Menu.Item style={submenuStyle} key="DriverIncentive" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/incentive`}>
                        Driver Incentive         
                      </Link>
                    </Menu.Item>
                  )}

                </SubMenu>
              )}
              

              {(adminPrivileges._manageDonationTransaction || adminPrivileges._manageCharities) && (
                <SubMenu
                  key="donation"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                    <i className="ion-ios-heart-outline" />
                      <span className="nav-text">Donation</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageCharities && (
                    <Menu.Item style={submenuStyle} key="charites" className="submenu">
                      <Link style={submenuColor} to={`${url}/donation/charites`}>
                        Charities
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageDonationTransaction && (
                    <Menu.Item style={submenuStyle} key="donationRecord" className="submenu">
                      <Link style={submenuColor} to={`${url}/donation/transaction`}>
                        Donation Record 
                      </Link>
                    </Menu.Item>
                  )}

                </SubMenu>
              )}

              {adminPrivileges._manageReferralCommission && (
                <Menu.Item key="ReferralCommission">
                  <Link to={`${url}/referralBonus`}>
                    <span className="isoMenuHolder">
                      <i className="ion-ios-people" />
                      <span className="nav-text">My Bonus</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
              

              {(adminPrivileges._manageDcoinRewards || adminPrivileges._manageRedemptionItem) && (
                <SubMenu
                  key="DcoinRewards"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                    <i className="ion-ribbon-a" />
                      <span className="nav-text">Dcoin Rewards</span>
                    </span>
                  }
                >
                  {adminPrivileges._manageRedemptionItem && (
                      <Menu.Item style={submenuStyle} key="redemptionItem" className="submenu">
                        <Link style={submenuColor} to={`${url}/DCoinReward/RedemptionItem`}>
                          Redemption Items
                        </Link>
                      </Menu.Item>
                  )}
                    
                  {adminPrivileges._manageReferralCommission && (
                      <Menu.Item style={submenuStyle} key="redemptionRecord" className="submenu">
                        <Link style={submenuColor} to={`${url}/DCoinReward/RedemptionRecord`}>
                        Dcoin Redemption Record 
                        </Link>
                      </Menu.Item>
                  )}

                </SubMenu>
              )}

                  
              {SCHEDULED_WASTE_START && adminPrivileges._manageDOERegistration && (
                <Menu.Item key="DOERegistration">
                  <Link to={`${url}/DOERegistration`}>
                    <span className="isoMenuHolder">
                      <i className="ion-android-hangout" />
                      <span className="nav-text">DOE Registration
                      
                        {(pending_count.DOE_Registration_waiting_approve > 0) && (
                          <span className="badge">({pending_count.DOE_Registration_waiting_approve})</span>
                        )}    
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              )}


              {(adminPrivileges._manageGrading || adminPrivileges._approveGrading) && (
                <SubMenu
                  key="grading"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-android-refresh" />
                      <span className="nav-text">Grading
                      
                        {(pending_count.grading_waiting_approve > 0) && (
                          <span className="badge">({pending_count.grading_waiting_approve})</span>
                        )}     
                      </span>
                    </span>
                  }
                >

                  {adminPrivileges._approveGrading && (
                    <Menu.Item style={submenuStyle} key="gradingApproval" className="submenu">
                      <Link style={submenuColor} to={`${url}/grading/approval`}>
                        DumpRecycling Pending Approval
                        {pending_count.grading_waiting_approve > 0 && (
                          <span className="badge">({pending_count.grading_waiting_approve})</span>
                        )} 
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageGrading && (
                    <Menu.Item style={submenuStyle} key="gradingPending" className="submenu">
                      <Link style={submenuColor} to={`${url}/grading/pending`}>
                        DumpRecycling Pending
                        
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageGrading && (
                    <Menu.Item style={submenuStyle} key="gradingWastePending" className="submenu">
                      <Link style={submenuColor} to={`${url}/grading/waste/pending`}>
                        DumpWaste Pending
                        
                      </Link>
                    </Menu.Item>
                  )}

                </SubMenu>
              )}
              

              {adminPrivileges._manageTrackDriver && (
                <Menu.Item key="track">
                  <Link to={`${url}/track`}>
                    <span className="isoMenuHolder">
                      <i className="ion-map" />
                      <span className="nav-text">Track Driver</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}


              {adminPrivileges._manageVerificationCode && (
                <Menu.Item key="verification-code">
                  <Link to={`${url}/verification-code`}>
                    <span className="isoMenuHolder">
                      <i className="ion-android-lock" />
                      <span className="nav-text">Verification Code</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}


              {(adminPrivileges._manageLorrySetup || adminPrivileges._manageLorryListing) && (
                <SubMenu
                  key="lorry"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                    <i className="ion-android-car" />
                      <span className="nav-text">Lorry Management
                      
                        {(pending_count.lorry_waiting_approve > 0) && (
                          <span className="badge">({pending_count.lorry_waiting_approve})</span>
                        )}    
                      </span>
                    </span>
                  }
                >
                {adminPrivileges._manageLorrySetup && (
                  <Menu.Item style={submenuStyle} key="lorryListing" className="submenu">
                    <Link style={submenuColor} to={`${url}/lorry`}>
                      Lorry Type
                    </Link>
                  </Menu.Item>
                )}

                {adminPrivileges._manageLorryListing && (
                  <Menu.Item style={submenuStyle} key="driverLorry" className="submenu">
                    <Link style={submenuColor} to={`${url}/driver/lorry`}>
                      Lorry Listing
                      {(pending_count.lorry_waiting_approve > 0) && (
                        <span className="badge">({pending_count.lorry_waiting_approve})</span>
                      )}  
                    </Link>
                  </Menu.Item>
                  )}

                </SubMenu>
              )}

              {(adminPrivileges._manageDumpsterSetup || adminPrivileges._manageDumpsterListing) && (
                <SubMenu
                  key="dumpster"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-trash-a" />
                      <span className="nav-text">Dumpster Management</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageDumpsterSetup && (

                    <Menu.Item style={submenuStyle} key="dumpster_cubic" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/cubic`}>
                        Dumpster Type
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageDumpsterListing && (

                    <Menu.Item style={submenuStyle} key="driverDumpster" className="submenu">
                      <Link style={submenuColor} to={`${url}/driver/dumpster`}>
                          Dumpster Listing
                      </Link>
                    </Menu.Item>

                  )}
                 
                </SubMenu>
              )}

              {(adminPrivileges._manageServiceSetup || adminPrivileges._manageServicePriceSetup || adminPrivileges._manageWasteTransportFeeSetup || adminPrivileges._manageWasteTransferCost || adminPrivileges._manageDumpWasteQuotation) && (
                <SubMenu
                  key="dumpWaste"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-trash-a" />
                      <span className="nav-text">{(adminPrivileges._manageDumpWasteQuotation && quotationCount.dump_waste && quotationCount.dump_waste > 0) ? <span className="red-badge">{quotationCount.dump_waste}</span> : ""}DumpWaste</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageServiceSetup && (

                    <Menu.Item style={submenuStyle} key="dumpster_service" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/service`}>
                        Dumpster Service
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageServicePriceSetup && (
                    
                    <Menu.Item style={submenuStyle} key="dumpster_waste_price" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/waste/price`}>
                        Dumpster Price List
                      </Link>
                    </Menu.Item>
                    
                  )}

                  {adminPrivileges._manageWasteTransportFeeSetup && (

                    <Menu.Item style={submenuStyle} key="dumpster_waste_transportfee" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/waste/transportfee`}>
                        Transport Fee
                      </Link>
                    </Menu.Item>
                      
                  )}

                  {adminPrivileges._manageWasteTransferCost && (

                    <Menu.Item style={submenuStyle} key="dumpster_waste_process" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/waste/process`}>
                        Waste Transfer Cost
                      </Link>
                    </Menu.Item>
                      
                  )}

                  {adminPrivileges._manageDumpWasteQuotation && (

                    <Menu.Item style={submenuStyle} key="dumpster_waste_quotation" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/waste/quotation`}>
                        {(quotationCount.dump_waste && quotationCount.dump_waste > 0) ? <span className="red-badge top-7">{quotationCount.dump_waste}</span> : ""}DumpWaste Quotation
                      </Link>
                    </Menu.Item>
                      
                  )}

                </SubMenu>
              )}

              
              {(adminPrivileges._manageRecyclableCategory || adminPrivileges._manageRecyclablePriceControl || adminPrivileges._manageRecyclablePrice || adminPrivileges._manageRecyclableTransferFee || adminPrivileges._manageServiceSetup || adminPrivileges._manageRecyclingExtraFee || adminPrivileges._manageDumpsterRentalCharges || adminPrivileges._viewDumpsterRentalCharges || adminPrivileges._manageRecyclingPoint || adminPrivileges._manageDumpRecyclingQuotation) && (
                <SubMenu
                  key="dumpRecycling"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-loop" />
                      <span className="nav-text">
                        {(adminPrivileges._manageDumpRecyclingQuotation && quotationCount.dump_recycling && quotationCount.dump_recycling > 0 && adminPrivileges._manageRecyclablePrice && newRecyclingPriceCount.dump_recycling && newRecyclingPriceCount.dump_recycling > 0) ? <span className="red-badge">{quotationCount.dump_recycling + newRecyclingPriceCount.dump_recycling}</span> : ""} 

                        {(adminPrivileges._manageDumpRecyclingQuotation && quotationCount.dump_recycling && quotationCount.dump_recycling > 0 && (!adminPrivileges._manageRecyclablePrice || !newRecyclingPriceCount.dump_recycling || newRecyclingPriceCount.dump_recycling <= 0)) ? <span className="red-badge">{quotationCount.dump_recycling}</span> : ""} 

                        {(adminPrivileges._manageRecyclablePrice && newRecyclingPriceCount.dump_recycling && newRecyclingPriceCount.dump_recycling > 0 && (!adminPrivileges._manageDumpRecyclingQuotation || !quotationCount.dump_recycling || quotationCount.dump_recycling <= 0)) ? <span className="red-badge">{newRecyclingPriceCount.dump_recycling}</span> : ""} 

                        DumpRecycling</span>
                    </span>
                  }
                >
                                         
                  {adminPrivileges._manageRecyclableCategory && (

                    <Menu.Item style={submenuStyle} key="recycling_category" className="submenu">
                      <Link style={submenuColor} to={`${url}/recycling/category`}>
                        Recyclable Category
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageRecyclablePriceControl && (

                    <Menu.Item style={submenuStyle} key="recycling_price_control" className="submenu">
                      <Link style={submenuColor} to={`${url}/recycling/price/control`}>
                        Recyclable item & Price Control Board                    
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageRecyclablePrice && (

                    <Menu.Item style={submenuStyle} key="recycling_price" className="submenu">
                      <Link style={submenuColor} to={`${url}/recycling/price`}>
                      {(newRecyclingPriceCount.dump_recycling && newRecyclingPriceCount.dump_recycling > 0) ? <span className="red-badge top-7">{newRecyclingPriceCount.dump_recycling}</span> : ""}Recyclable Purchase Price List
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageRecyclableTransferFee && (

                    <Menu.Item style={submenuStyle} key="dumpster_recycling_process" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/recycling/process`}>
                        Recyclable Process Cost
                      </Link>
                    </Menu.Item>
                    )}

                  {adminPrivileges._manageRecyclingExtraFee && (

                    <Menu.Item style={submenuStyle} key="recycling_extra_fee" className="submenu">
                      <Link style={submenuColor} to={`${url}/recycling/extraFee`}>
                        Recycling Extra Fee
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageServiceSetup && (

                    <Menu.Item style={submenuStyle} key="recycling_dumpster" className="submenu">
                      <Link style={submenuColor} to={`${url}/recycling/dumpster`}>
                        Recycling Dumpster
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageDumpsterRentalCharges && (
                    <Menu.Item style={submenuStyle} key="dumpster_price_recycling_set" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/recycling/price/set`}>
                        Recycling Dumpster Price Control
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._viewDumpsterRentalCharges && !adminPrivileges._manageDumpsterRentalCharges  && (
                    <Menu.Item style={submenuStyle} key="dumpster_price_recycling_list" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/recycling/price/list`}>
                        Recycling Dumpster Price List
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageRecyclingPoint && (
                    <Menu.Item style={submenuStyle} key="recycling_sales_price" className="submenu">
                      <Link style={submenuColor} to={`${url}/recycling/salesPrice`}>
                        Recycling Sales Price
                      </Link>
                    </Menu.Item>
                  )}


                  {adminPrivileges._manageDumpRecyclingQuotation && (

                    <Menu.Item style={submenuStyle} key="dumpster_recycling_quotation" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/recycling/quotation`}>
                        {(quotationCount.dump_recycling && quotationCount.dump_recycling > 0) ? <span className="red-badge top-7">{quotationCount.dump_recycling}</span> : ""}DumpRecycling Quotation
                      </Link>
                    </Menu.Item>
                    
                  )}


                  {adminPrivileges._manageRecyclablePriceControl && (

                    <Menu.Item style={submenuStyle} key="sales_price_update" className="submenu">
                      <Link style={submenuColor} to={`${url}/recycling/salesPriceUpdate`}>
                        Sales Price Update History
                      </Link>
                    </Menu.Item>
                    
                  )}


                </SubMenu>
              )}

              
              {(adminPrivileges._manageExpressRecyclablePriceControl || adminPrivileges._manageExpressRecyclablePrice || adminPrivileges._manageDumpExpressQuotation) && (
                <SubMenu
                  key="dumpExpress"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-loop" />
                      <span className="nav-text">
                        {(adminPrivileges._manageDumpExpressQuotation && quotationCount.dump_express && quotationCount.dump_express > 0 && adminPrivileges._manageExpressRecyclablePrice && newRecyclingPriceCount.dump_express && newRecyclingPriceCount.dump_express > 0) ? <span className="red-badge">{quotationCount.dump_express + newRecyclingPriceCount.dump_express}</span> : ""} 

                        {(adminPrivileges._manageDumpExpressQuotation && quotationCount.dump_express && quotationCount.dump_express > 0 && (!adminPrivileges._manageExpressRecyclablePrice || !newRecyclingPriceCount.dump_express || newRecyclingPriceCount.dump_express <= 0)) ? <span className="red-badge">{quotationCount.dump_express}</span> : ""} 

                        {(adminPrivileges._manageExpressRecyclablePrice && newRecyclingPriceCount.dump_express && newRecyclingPriceCount.dump_express > 0 && (!adminPrivileges._manageDumpExpressQuotation || !quotationCount.dump_express || quotationCount.dump_express <= 0)) ? <span className="red-badge">{newRecyclingPriceCount.dump_express}</span> : ""} 

                        DumpExpress</span>
                    </span>
                  }
                >  

                  {adminPrivileges._manageExpressRecyclablePriceControl && (

                    <Menu.Item style={submenuStyle} key="express_recycling_price_control" className="submenu">
                      <Link style={submenuColor} to={`${url}/express/price/control`}>
                        Recyclable item & Price Control Board
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageExpressRecyclablePrice && (

                    <Menu.Item style={submenuStyle} key="express_recycling_price" className="submenu">
                      <Link style={submenuColor} to={`${url}/express/price`}>
                      {(newRecyclingPriceCount.dump_express && newRecyclingPriceCount.dump_express > 0) ? <span className="red-badge top-7">{newRecyclingPriceCount.dump_express}</span> : ""}Recyclable Purchase Price List
                      </Link>
                    </Menu.Item>
                  )}


                  {adminPrivileges._manageDumpExpressQuotation && (

                    <Menu.Item style={submenuStyle} key="dumpster_express_quotation" className="submenu">
                      <Link style={submenuColor} to={`${url}/dumpster/express/quotation`}>
                        {(quotationCount.dump_express && quotationCount.dump_express > 0) ? <span className="red-badge top-7">{quotationCount.dump_express}</span> : ""}DumpExpress Quotation
                      </Link>
                    </Menu.Item>
                    
                  )}


                  {adminPrivileges._manageExpressRecyclablePriceControl && (

                    <Menu.Item style={submenuStyle} key="express_sales_price_update" className="submenu">
                      <Link style={submenuColor} to={`${url}/express/salesPriceUpdate`}>
                        Sales Price Update History
                      </Link>
                    </Menu.Item>
                    
                  )}

                </SubMenu>
              )}

              {SCHEDULED_WASTE_START && (adminPrivileges._manageScheduledWasteSetup || adminPrivileges._manageScheduledWastePriceSetup || adminPrivileges._viewScheduledWastePrice || adminPrivileges._manageScheduledWasteTransportFeeSetup || adminPrivileges._viewScheduledWasteTransportFee || adminPrivileges._manageScheduledWasteContainer || adminPrivileges._manageScheduledWasteTransferCost) && (
                <SubMenu
                  key="dumpScheduledWaste"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-trash-a" />
                      <span className="nav-text">DumpScheduledWaste</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageScheduledWasteSetup && (

                    <Menu.Item style={submenuStyle} key="scheduled_waste" className="submenu">
                      <Link style={submenuColor} to={`${url}/scheduled_waste/waste`}>
                        Scheduled Waste
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageScheduledWastePriceSetup && (

                    <Menu.Item style={submenuStyle} key="scheduled_waste_price_set" className="submenu">
                      <Link style={submenuColor} to={`${url}/scheduled_waste/price/set`}>
                        Waste Price Control
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._viewScheduledWastePrice && !adminPrivileges._manageScheduledWastePriceSetup && (
                    <Menu.Item style={submenuStyle} key="scheduled_waste_price_list" className="submenu">
                      <Link style={submenuColor} to={`${url}/scheduled_waste/price/list`}>
                      Waste Price List
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageScheduledWasteTransportFeeSetup && (

                    <Menu.Item style={submenuStyle} key="scheduled_waste_lorry_price_set" className="submenu">
                      <Link style={submenuColor} to={`${url}/scheduled_waste/lorry/price/set`}>
                        Lorry Transport Fee Control
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._viewScheduledWasteTransportFee && !adminPrivileges._manageScheduledWasteTransportFeeSetup && (
                    <Menu.Item style={submenuStyle} key="scheduled_waste_lorry_price_list" className="submenu">
                      <Link style={submenuColor} to={`${url}/scheduled_waste/lorry/price/list`}>
                        Lorry Transport Fee List
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageScheduledWasteContainer && (

                    <Menu.Item style={submenuStyle} key="scheduled_waste_container" className="submenu">
                      <Link style={submenuColor} to={`${url}/scheduled_waste/container`}>
                        Waste Container
                      </Link>
                    </Menu.Item>
                  )}
                  
                  {adminPrivileges._manageScheduledWasteTransferCost && (

                    <Menu.Item style={submenuStyle} key="scheduled_waste_process_cost" className="submenu">
                      <Link style={submenuColor} to={`${url}/scheduled_waste/processCost`}>
                        Waste Process Cost
                      </Link>
                    </Menu.Item>
                  )}
                  
                </SubMenu>
              )}

              {(adminPrivileges._manageDisposalPoint || adminPrivileges._manageLandfillFee) && (
                <SubMenu
                  key="landfillPoint"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-location" />
                      <span className="nav-text">Waste Disposal Site Management</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageDisposalPoint && (
                    <Menu.Item style={submenuStyle} key="landfill_point" className="submenu">
                      <Link style={submenuColor} to={`${url}/point/landfill`}>
                        Waste Disposal Point
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageLandfillFee && (
                    <Menu.Item style={submenuStyle} key="landfill_adjustment" className="submenu">
                      <Link style={submenuColor} to={`${url}/point/landfill/adjustment`}>
                        LandFill Fee Management
                      </Link>
                    </Menu.Item>
                  )}

                </SubMenu>
              )}

              {(adminPrivileges._manageRecyclingPoint || adminPrivileges._manageRecyclingPointAdjustment) && (
                <SubMenu
                  key="recyclingPoint"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-flag" />
                      <span className="nav-text">Recycling Point Management</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageRecyclingPoint && (
                    <Menu.Item style={submenuStyle} key="landfill_point_recycling" className="submenu">
                      <Link style={submenuColor} to={`${url}/point/landfill/recycling`}>
                        Recycling Point
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageRecyclingPointAdjustment && (
                    <Menu.Item style={submenuStyle} key="landfill_adjustment_recycling" className="submenu">
                      <Link style={submenuColor} to={`${url}/point/landfill/adjustment/recycling`}>
                        Recycling Point Adjustment
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

                    
              {SCHEDULED_WASTE_START && adminPrivileges._manageRecoveryCompany && (
                <Menu.Item key="scheduledRecoverer">
                  <Link to={`${url}/scheduled_waste/recoverer`}>
                    <span className="isoMenuHolder">
                      <i className="ion-ios-flag-outline" />
                      <span className="nav-text">Scheduled Recovery Company</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {adminPrivileges._manageWeighingPoint && (
                <Menu.Item key="weighingPoint">
                  <Link to={`${url}/point/weighing`}>
                    <span className="isoMenuHolder">
                      <i className="ion-ios-flag-outline" />
                      <span className="nav-text">Weighing Point</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {adminPrivileges._manageFeedback && (
                <Menu.Item key="feedback">
                  <Link to={`${url}/feedback`}>
                    <span className="isoMenuHolder">
                      <i className="ion-android-chat" />
                      <span className="nav-text">Feedback
                        {(pending_count.feeback_waiting_resolve > 0) && (
                          <span className="badge">({pending_count.feeback_waiting_resolve})</span>
                        )}  
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {adminPrivileges._managePartner && (
                <Menu.Item key="merchantRegistration">
                  <Link to={`${url}/merchantRegistration`}>
                    <span className="isoMenuHolder">
                      <i className="ion-android-drafts" />
                      <span className="nav-text">Partner Management
                        {(pending_count.partner_manager_waiting_verify > 0) && (
                          <span className="badge">({pending_count.partner_manager_waiting_verify})</span>
                        )}  
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {adminPrivileges._managePromotion && (
                <Menu.Item key="promotion">
                  <Link to={`${url}/promotion`}>
                    <span className="isoMenuHolder">
                      <i className="ion-pricetags" />
                      <span className="nav-text">Promo Code</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {adminPrivileges._manageNotification && (
                <Menu.Item key="notification">
                  <Link to={`${url}/notification`}>
                    <span className="isoMenuHolder">
                      <i className="ion-ios-bell" />
                      <span className="nav-text">Notification & News</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {adminPrivileges._manageWhatsapp && (
                <Menu.Item key="whatsapp">
                  <Link to={`${url}/whatsapp`}>
                    <span className="isoMenuHolder">
                      <i className="ion-ios-bell" />
                      <span className="nav-text">Whatsapp Notification</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {(adminPrivileges._manageWasteCollectionReport || adminPrivileges._manageRecyclableCollectionReport || adminPrivileges._manageLandfillReport || adminPrivileges._manageScheduledWasteCollectionReport || adminPrivileges._manageMonthlyStockSummaryReport || adminPrivileges._manageDriverTripSummaryReport || adminPrivileges._manageOrderDistanceSummaryReport || adminPrivileges._manageMonthlyWasteSummaryReport) && (
                <SubMenu
                  key="reporting"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-paper-outline" />
                      <span className="nav-text">Reporting</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageWasteCollectionReport && (
                    <Menu.Item style={submenuStyle} key="wasteSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/wasteSummary`}>
                       Waste Collection Report
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageRecyclableCollectionReport && (
                    <Menu.Item style={submenuStyle} key="recyclableSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/recyclableSummary`}>
                        Recyclable Collection Report
                      </Link>
                    </Menu.Item>
                  )}

                  {SCHEDULED_WASTE_START && adminPrivileges._manageScheduledWasteCollectionReport && (
                    <Menu.Item style={submenuStyle} key="scheduledWasteSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/scheduledWasteSummary`}>
                        Scheduled Waste Collection Report
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageLandfillReport && (
                    <Menu.Item style={submenuStyle} key="landfillSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/landfillSummary`}>
                        Landfill Report
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageMonthlyStockSummaryReport && (
                    <Menu.Item style={submenuStyle} key="recyclingStockSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/recyclingStockSummary`}>
                        DumpRecycling Stock Summary Report
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageMonthlyStockSummaryReport && (
                    <Menu.Item style={submenuStyle} key="expressStockSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/expressStockSummary`}>
                        DumpExpress Stock Summary Report
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageMonthlyWasteSummaryReport && (
                    <Menu.Item style={submenuStyle} key="wasteStockSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/wasteStockSummary`}>
                        DumpWaste Waste Summary Report
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageDriverTripSummaryReport && (
                    <Menu.Item style={submenuStyle} key="driverTripSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/driverTripSummary`}>
                       Driver Trip Summary
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageOrderDistanceSummaryReport && (
                    <Menu.Item style={submenuStyle} key="orderDistanceSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/orderDistanceSummary`}>
                       Order Distance Summary
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageReferralCommissionSummaryReport && (
                    <Menu.Item style={submenuStyle} key="referralCommissionSummary" className="submenu">
                      <Link style={submenuColor} to={`${url}/reporting/referralBonusSummary`}>
                        Referral Bonus Summary
                      </Link>
                    </Menu.Item>
                  )}

                </SubMenu>
              )}

              {(adminPrivileges._manageAccountingReport || adminPrivileges._manageAccountingReportDRPurchaseBill || adminPrivileges._manageAccountingReportDEPurchaseBill) && (
                <SubMenu
                  key="accounting_report"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-paper-outline" />
                      <span className="nav-text">Accounting Report</span>
                    </span>
                  }
                >
                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="dumpWasteInvoiceSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/dumpWasteInvoiceSummary`}>
                        DumpWaste Tax Invoice
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="dumpRecyclingInvoiceSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/dumpRecyclingInvoiceSummary`}>
                          DumpRecycling Tax Invoice
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="specialInvoiceSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/specialInvoiceSummary`}>
                          DumpRecycling Special Invoice
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && SCHEDULED_WASTE_START && (
                      <Menu.Item style={submenuStyle} key="scheduledWasteInvoiceSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/scheduledWasteInvoiceSummary`}>
                        DumpScheduledWaste Tax Invoice
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReportDRPurchaseBill && (
                      <Menu.Item style={submenuStyle} key="dumpRecyclingPurchaseBillSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/dumpRecyclingPurchaseBillSummary`}>
                          DumpRecycling Purchase Bill
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReportDEPurchaseBill && (
                      <Menu.Item style={submenuStyle} key="dumpExpressPurchaseBillSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/dumpExpressPurchaseBillSummary`}>
                          DumpExpress Purchase Bill
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="dumpRecyclingStoreReceiptSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/dumpRecyclingStoreReceiptSummary`}>
                          DumpRecycling Store Receipt
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="dumpExpressStoreReceiptSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/dumpExpressStoreReceiptSummary`}>
                          DumpExpress Store Receipt
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="dumpWasteWasteReceiptSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/dumpWasteWasteReceiptSummary`}>
                          DumpWaste Waste Receipt
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="recyclableSalesInvoiceSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/recyclableSalesInvoiceSummary`}>
                          Recyclable Sales Invoice
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="fillingExtensionInvoiceSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/fillingExtensionInvoiceSummary`}>
                          Filling Extension Tax Invoice
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="addOnTripInvoiceSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/addOnTripInvoiceSummary`}>
                          Add On Trip Tax Invoice
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="driverIncentiveDumpWasteSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/driverIncentiveDumpWasteSummary`}>
                          DumpWaste Driver Incentive
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="driverIncentiveDumpRecyclingSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/driverIncentiveDumpRecyclingSummary`}>
                          DumpRecycling Driver Incentive
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && SCHEDULED_WASTE_START && (
                      <Menu.Item style={submenuStyle} key="driverIncentiveDumpScheduledWasteSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/driverIncentiveDumpScheduledWasteSummary`}>
                          DumpScheduledWaste Driver Incentive
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="driverIncentiveFillingExtensionSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/driverIncentiveFillingExtensionSummary`}>
                          Driver Incentive (Filling Extention)
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="bookingFeeSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/bookingFeeSummary`}>
                          Booking Fee
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="processFeeSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/processFeeSummary`}>
                          Process Fee
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="driverReimbursementSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/driverReimbursementSummary`}>
                          Driver Reimbursement
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="landfillFeeSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/landfillFeeSummary`}>
                          Landfill Fee
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="userLoadingFeeSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/userLoadingFeeSummary`}>
                          User Loading Fee
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="driverLoadingPaymentSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/driverLoadingPaymentSummary`}>
                          Driver Loading Payment
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="sortingFeeSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/sortingFeeSummary`}>
                          Sorting Fee
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="oversizeFeeSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/oversizeFeeSummary`}>
                          Oversize Fee
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="recyclingRecyclablePurchaseSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/recyclingRecyclablePurchaseSummary`}>
                          DumpRecycling Recyclable Purchase
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="expressRecyclablePurchaseSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/expressRecyclablePurchaseSummary`}>
                          DumpExpress Recyclable Purchase
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="graderDailySettlement" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/graderDailySettlement`}>
                          Grader Daily Settlement
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="packingListDumpExpressSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/packingListDumpExpressSummary`}>
                        DumpExpress Packing List
                        </Link>
                      </Menu.Item>
                    )}

                    {adminPrivileges._manageAccountingReport && (
                      <Menu.Item style={submenuStyle} key="packingListDumpRecyclingSummary" className="submenu">
                        <Link style={submenuColor} to={`${url}/reporting/packingListDumpRecyclingSummary`}>
                          DumpRecycling Packing List
                        </Link>
                      </Menu.Item>
                    )}
                    
                </SubMenu>
              )}

              {(adminPrivileges._manageAdminAcount || adminPrivileges._manageAdminPrivileges) && (
                <SubMenu
                  key="admin"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-people" />
                      <span className="nav-text">Admin Management</span>
                    </span>
                  }
                >

                  {adminPrivileges._manageAdminAcount && (
                    <Menu.Item style={submenuStyle} key="admin_listing" className="submenu">
                      <Link style={submenuColor} to={`${url}/admin`}>
                        Admin Listing
                      </Link>
                    </Menu.Item>
                  )}

                  {adminPrivileges._manageAdminPrivileges && (
                    <Menu.Item style={submenuStyle} key="admin_privileges" className="submenu">
                      <Link style={submenuColor} to={`${url}/admin/privileges`}>
                        Admin Role & Privileges
                      </Link>
                    </Menu.Item>
                  )}

                </SubMenu>
              )}

              {(adminPrivileges._manageMasterSetup) && (
                <SubMenu
                  key="setting"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-gear-a" />
                      <span className="nav-text">Setting</span>
                    </span>
                  }
                >
                  <Menu.Item style={submenuStyle} key="order_type">
                    <Link style={submenuColor} to={`${url}/order_type`}>
                      Order Type
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="distance">
                    <Link style={submenuColor} to={`${url}/distance`}>
                      Distance
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="carbonFootprint">
                    <Link style={submenuColor} to={`${url}/carbonFootprint`}>
                      My Environmental Footprint
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="referralBonusControl">
                    <Link style={submenuColor} to={`${url}/referralBonusControl`}>
                      Referral Bonus Control
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="inviteFriendSetting">
                    <Link style={submenuColor} to={`${url}/inviteFriendSetting`}>
                      Invite Friends
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="driverSubscriptionSettings">
                    <Link style={submenuColor} to={`${url}/driverSubscriptionSettings`}>
                      Driver Subscription
                    </Link>
                  </Menu.Item>
                  
                  <Menu.Item style={submenuStyle} key="settings">
                    <Link style={submenuColor} to={`${url}/settings`}>
                      Settings
                    </Link>
                  </Menu.Item>

                </SubMenu>
              )}

            </Menu>

            <Divider />

            <Menu
              onClick={this.props.onLogout}
              theme="dark"
              className="isoDashboardMenu"
              style={{ paddingTop: "0" }}
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              <Menu.Item key="logout">
                <span className="isoMenuHolder">
                  <i className="ion-log-out" />
                  <span className="nav-text">Logout</span>
                </span>
              </Menu.Item>
            </Menu>
            {!collapsed && (
              <p className="copyright">
                {SITE_CONFIG.footerText}
                <br />{LinkCell(`(v${APP_VERSION})`, `/version`)}
              </p>
            )}
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

const mapStateToProps = state => {

  const { profileData, jwtToken } = state.Auth;
  const { pending_count } = state.FirebaseDB;
  const { quotationCount } = state.UserOrganization;
  const { newRecyclingPriceCount } = state.Recycling;

  return {
    profileData, jwtToken,
    app: state.App,
    height: state.App.height,
    pending_count,
    quotationCount, newRecyclingPriceCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleOpenDrawer: () => dispatch(actions.toggleOpenDrawer()),
    onChangeOpenKeys: openKeys => dispatch(actions.changeOpenKeys(openKeys)),
    onChangeCurrent: current => dispatch(actions.changeCurrent(current)),
    onToggleCollapsed: () => dispatch(actions.toggleCollapsed()),
    onLogout: () => dispatch(actions.logoutConfirmation()),   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
